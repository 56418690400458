import { createReducer, Reducer } from "typesafe-actions";
import { moneyItemsActions } from "../../actions/moneyItems.actions";
import { MoneyItemEntity } from "./MoneyItemEntity";
import { denormalizeCollectionResponse } from "../../../common/denormalizeResponse";
import { calculateDummyBalance } from './calculateDummyBalance';
import _ from "lodash";

export type MoneyItemsState = {
  [key: string]: MoneyItemEntity;
};

export const moneyItemsReducer: Reducer<MoneyItemsState, any> =
  createReducer<MoneyItemsState>({})
    .handleAction(
      moneyItemsActions.loadSuccess,
      (
        _state: MoneyItemsState,
        action: ReturnType<typeof moneyItemsActions.loadSuccess>
      ) => {
        const result = denormalizeCollectionResponse(action.payload);
        return calculatePreviousBalance(result);
      }
    )
    .handleAction(
      moneyItemsActions.deleteSingle,
      (
        state: MoneyItemsState,
        action: ReturnType<typeof moneyItemsActions.deleteSingle>
      ) => {
        delete state[action.payload.itemId];

        return {
          ...state,
        };
      }
    )
    .handleAction(
      moneyItemsActions.reorder,
      (
        state: MoneyItemsState,
        action: ReturnType<typeof moneyItemsActions.reorder>
      ) => {
        const { activeId, overId } = action.payload;

        const overItem = state[overId];
        const activeItem = state[activeId];

        if (overItem.transactionOn === activeItem.transactionOn) {
          return state;
        }

        const overwrittenState = {
          ...state,
          [activeId]: {
            ...activeItem,
            transactionOn: overItem.transactionOn,
          },
        };

        const itemsWithSameTransactionDate = Object.values(overwrittenState);

        const sortedItems = itemsWithSameTransactionDate.sort((a, b) => {
          if (a.transactionOn < b.transactionOn) {
            return -1;
          } else if (a.transactionOn > b.transactionOn) {
            return 1;
          }

          const amountABankNumber = parseFloat(a.amountBank);
          const amountBBankNumber = parseFloat(b.amountBank);

          if (amountABankNumber < amountBBankNumber) {
            return -1;
          } else if (amountABankNumber > amountBBankNumber) {
            return 1;
          }

          return 0;
        });

        const highestOrder = sortedItems[0].order;

        let stateChange = sortedItems
          .map((item, index) => {
            return {
              ...item,
              order: highestOrder + index,
            };
          })
          .reduce(
            (prev: Record<string, MoneyItemEntity>, curr: MoneyItemEntity) => {
              prev[curr.id] = {
                ...curr,
              };

              return prev;
            },
            {}
          );

        const newState = {
          ...state,
          ...stateChange,
        };

        return {
          ...newState,
        };
      }
    )
    .handleAction(
      moneyItemsActions.updateSingle,
      (
        state: MoneyItemsState,
        action: ReturnType<typeof moneyItemsActions.updateSingle>
      ) => {
        const newState = {
          ...state,
          [action.payload.id!.toString()]: {
            ...state[action.payload.id!.toString()],
            ...action.payload,
          },
        };

        return calculateDummyBalance(newState)
      }
    )
    .handleAction(
      moneyItemsActions.updateSingleReccuring,
      (
        state: MoneyItemsState,
        action: ReturnType<typeof moneyItemsActions.updateSingleReccuring>
      ) => {
        const { id, ...actionPayloadWithoutId } = action.payload

        const newState = {
          ...state,
          [action.payload.id!.toString()]: {
            ...state[action.payload.id!.toString()],
            ...action.payload,
          },
        };

        const currentItem = state[action.payload.id!];

        for (let i = 0; i < currentItem.inheritedChildrenIds.length; i++) {
          const currentChildId = currentItem.inheritedChildrenIds[i];
          const currentChild = state[currentChildId];

          if (!currentChild) {
            continue;
          }

          newState[currentChildId.toString()] = {
            ...currentChild,
            ...actionPayloadWithoutId
          }
        }

        for (let i = 0; i < currentItem.siblingsIds.length; i++) {
          const currentSiblingId = currentItem.siblingsIds[i];
          const currentSibling = state[currentSiblingId];

          if (!currentSibling) {
            continue;
          }

          newState[currentSiblingId.toString()] = {
            ...currentSibling,
            ...actionPayloadWithoutId
          }
        }

        return calculateDummyBalance(newState)
      }
    )
    .handleAction(
      moneyItemsActions.updateSingleSuccessful,
      (
        state: MoneyItemsState,
        action: ReturnType<typeof moneyItemsActions.updateSingleSuccessful>
      ) => {
        return {
          ...state,
          [action.payload.id]: {
            ...state[action.payload.id],
            ...action.payload,
          },
        };
      }
    );

function calculatePreviousBalance(state: MoneyItemsState) {
  const sortedItems = Object.values(state).sort((a, b) => {
    if (a.order < b.order) {
      return -1;
    } else if (a.order > b.order) {
      return 1;
    }

    return 0;
  })

  const items = sortedItems.map((item, index) => {
    const currItem = item

    return {
      ...currItem,
      previousBalance: parseFloat(item.vabankBalance) - parseFloat(item.amountBank)
    }
  })

  return _.keyBy(items, 'id');
}
