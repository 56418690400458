import React from "react";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.body2,
    color: 'rgb(97, 26, 21)',
    backgroundColor: 'rgb(253, 236, 234)',
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    padding: '6px 16px',
    borderRadius: 4,
  },
  icon: {
    marginRight: 12,
    padding: '7px 0',
    display: 'flex',
    fontSize: 22,
    color: '#f44336'
  },
  message: {
    padding: '8px 0',
  }
}));

const Alert = ({ msg, open }) => {
  const classes = useStyles();

  if (!open) {
    return null;
  }

  return (
    <div
      role="alert"
      className={classes.root}
    >
      <div className={classes.icon}>
        <ErrorOutlineIcon />
      </div>
      <div className={classes.message}>{msg}</div>
    </div>
  );
};

export default Alert;
