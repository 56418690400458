import { Moment } from "moment";
import { createAction } from "typesafe-actions";

export type DateRangeFilterInput = {
    startDate: Moment,
    endDate: Moment
}

export const changeDateRange = createAction('filters/changeDateRange')<DateRangeFilterInput>()

export const changeSearch = createAction('filters/changeSearch')<string>()

export type UpdateDetailFiltersActionPayload = {
    payeeId: string | null,
    projectId: string | null,
    moneyCategories: any[] | null,
    moneyAccountId: string | null,
    amountFrom: string | null,
    amountTo: string | null,
    recurringParents: boolean,
    recurringChildren: boolean,
    recurringOrphan: boolean,
    tags: string | null,
}

export const updateDetailFilters = createAction('filters/updateDetailFilters')<UpdateDetailFiltersActionPayload>();

export const resetDetailFilters = createAction('filters/resetDetailFilters')<void>();

export const filtersActions = {
    changeDateRange,
    changeSearch,
    updateDetailFilters,
    resetDetailFilters
}
