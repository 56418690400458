import { Button, IconButton, Menu, MenuItem } from "@material-ui/core";
import React from "react";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useHistory } from "react-router";
import { DUPLICATE_PAYMENT } from "../../../../constants/routes";

export const AddiotionalActions: React.FC<{
    id: string
}> = ({
    id
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const history = useHistory();

  const handleDuplicate = () => {
    history.push(DUPLICATE_PAYMENT.replace(':id', id));

    handleClose();
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        edge="end"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted={false}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDuplicate}>Duplicate</MenuItem>
      </Menu>
    </div>
  );
};
