import React from 'react'

export const UserContext = React.createContext()

function UserProvider({ children }) {

	const [email, setEmail] = React.useState("")

	return (
		<UserContext.Provider value={{email, setEmail}}>
			{ children }
		</UserContext.Provider >
	)
}

export default UserProvider;