import { createReducer } from "typesafe-actions";
import { denormalizeCollectionResponse } from "../../../common/denormalizeResponse";
import { WithId } from "../../../common/withId";
import { appActions } from "../../actions/app.actions";
import { moneyCategoriesActions } from "../../actions/moneyCategories.actions";
import { MoneyCategoryApiEntity } from "./MoneyCategoryApiEntity";

export type MoneyCategoriesState = {
    [key: string]: WithId<MoneyCategoryApiEntity>;
};

export const moneyCategoriesReducer = createReducer<MoneyCategoriesState>(
    {}
).handleAction(
    appActions.loaded,
    (
        state: MoneyCategoriesState,
        action: ReturnType<typeof appActions.loaded>
    ) => {
        const result = denormalizeCollectionResponse(action.payload.moneyCategories);

        return result
    }
);
