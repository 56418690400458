import { createReducer } from "typesafe-actions";
import { appActions } from "../../actions/app.actions";
import { camelCase, mapKeys } from "lodash";

export type BalanceState = {
  totalBalance: string;
  estimatedBalance: string;
  difference: string;
};

export const balanceReducer = createReducer<BalanceState | null>({
  totalBalance: "...",
  estimatedBalance: "...",
  difference: "...",
}).handleAction(
  appActions.loadBalanceSuccess,
  (
    state: BalanceState,
    action: ReturnType<typeof appActions.loadBalanceSuccess>
  ) => {
    const result = mapKeys(action.payload, (value, key) => camelCase(key));

    return result;
  }
);
