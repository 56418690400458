import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Redirect, Route } from "react-router-dom";
import { RootState } from "../../model/root.reducer";
import * as routes from "../../constants/routes";
import HomePage from "../../components/Home";
import SignInPage from "../../components/SignIn";
import { CreatePayeeContainer } from "../CreatePayee/CreatePayeeContainer";
import { CreateProjectContainer } from "../CreateProject/CreateProjectContainer";
import _ from "lodash";

export const Routes: React.FC = () => {
  const filters = useSelector((state: RootState) => state.filters);

  const history = useHistory();
  const location = useLocation();

  const isReady = useSelector((state: RootState) => state.app.isReady);

  useEffect(() => {
    if (!isReady) {
      return;
    }

    history.push(`${location.pathname}?filters=${JSON.stringify(_.omitBy(filters, _.isNil))}`);
  }, [
    filters,
    filters.endOfMonth,
    filters.startOfMonth,
    filters.search,
    filters.projectId,
    filters.payeeId,
    filters.moneyCategories,
    filters.moneyAccountId,
  ]);

  return (
    <>
      <Route path={routes.SIGN_IN} exact component={() => <SignInPage />} />
      <Route path={routes.HOME} component={() => <HomePage />} />
      <Route
        path="/home/payee/create"
        exact
        component={() => <CreatePayeeContainer />}
      />
      <Route
        path="/home/project/create"
        exact
        component={() => <CreateProjectContainer />}
      />
      <Route path="/" exact render={() => <Redirect to="/home" />} />
    </>
  );
};
