import Rest from "./Rest";
import Endpoints from "../constants/Endpoints";
import axios from "axios";

const TYPE = "money-items";

const get = async (filters) => {
  const f = {};
  for (let filter in filters) {
    const key = `filter[${filter}]`;
    f[key] = filters[filter];
  }

  try {
    const req = await Rest({
      method: "GET",
      url: Endpoints.moneyItems,
      params: {
        sort: "transaction-on,amount-bank,created-at",
        "filter[genre]": "virtual",
        lite: "1",
        ...f,
      },
    });

    return req.data;
  } catch (error) {
    console.log("err", { error });
  }
};

const getItem = async (id) => {
  if (!Boolean(id)) return;
  try {
    const data = await get({ id });
    if (data.length > 0)
      return {
        id: data[0].id,
        ...data[0].attributes,
      };
    return;
  } catch (error) {}
};

const update = async ({ id, tags = "", ...data }) => {
  try {
    await Rest({
      method: "PATCH",
      url: `${Endpoints.moneyItems}/${id}`,
      data: {
        data: {
          id,
          type: TYPE,
          attributes: data,
          relationships: {},
        },
				tags,
      },
    });
  } catch (error) {
    console.log("err", { error });
  }
};
const recurringUpdate = async ({ id, ...data }) => {
  try {
    await Rest({
      method: "PATCH",
      url: `${Endpoints.moneyItems}/${id}/recurring_update`,
      data: {
        data: {
          id,
          type: TYPE,
          attributes: data,
          relationships: {},
        },
      },
    });
  } catch (error) {
    console.log("err", { error });
  }
};

const deleteItem = async (id) => {
  try {
    await Rest({
      method: "DELETE",
      url: `${Endpoints.moneyItems}/${id}`,
    });
  } catch (error) {}
};

const recurringDeleteItem = async (id) => {
  try {
    await Rest({
      method: "DELETE",
      url: `${Endpoints.moneyItems}/${id}/recurring_delete`,
    });
  } catch (error) {}
};

const getNames = async (filters) => {
  try {
    const items = await get(filters);
    return items.map((x) => ({
      id: x.id,
      name: x.attributes.name,
      date: x.attributes["transaction-on"],
      parentId: x.attributes["inherited-parent-id"],
    }));
  } catch (error) {}
};


const getTags = async () => {
  try {
    const availableTags = await Rest({
      method: "GET",
      url: `${Endpoints.moneyItems}/tags`,
    });
    return availableTags.data.map((tag) => ({
			id: tag.id,
      name: tag.attributes.name,
			type: 'TAG'
    }));
  } catch (error) {
    console.log("err", { error });
  }
};

const create = async (body, tags) => {
  try {
    const res = await Rest({
      method: "POST",
      url: Endpoints.moneyItems,
      data: {
        data: {
          type: TYPE,
          attributes: body,
          relationships: {},
        },
				tags,
      },
    });
    return res.data.id;
  } catch (error) {}
};

const info = async (id) => {
  try {
    const result = await Rest({
      method: "GET",
      url: `${Endpoints.moneyItems}/${id}/info`,
    });
    return result;
  } catch (error) {}
};

const getTotalBalance = async () => {
  try {
    const result = await Rest({
      method: "GET",
      url: `${Endpoints.moneyItems}/total_balance`,
    });
    return result;
  } catch (error) {}
};

const getVabankChartsData = async (params) => {
  try {
    const result = await Rest({
      method: "GET",
      url: `${Endpoints.moneyItems}/vabank_graphs_data`,
      params: params,
    });
    return result;
  } catch (error) {}
};

const getExport = async (variant, filters) => {
  const f = {};
  for (let filter in filters) {
    const key = `filter[${filter}]`;
    f[key] = filters[filter];
  }

  try {
    const token = localStorage.getItem("token");

    const req = await axios({
      method: "GET",
      responseType: "blob",
      url: Endpoints.moneyItems + "/vabank_export",
      params: {
        variant: variant,
        sort: "transaction-on,amount-bank,created-at",
        "filter[genre]": "virtual",
        lite: "1",
        ...f,
      },
      headers: {
        authorization: "Bearer " + token,
        "Content-Type": "application/vnd.api+json",
      },
    });

    return {
      data: req.data,
      type: req.headers["content-type"],
      filename: req.headers["content-disposition"]
        .split(";")[1]
        .split("filename=")[1]
        .replaceAll('"', ""),
    };
  } catch (error) {
    console.log("err", { error });
  }
};

export default {
  get,
  getItem,
  update,
  recurringUpdate,
  deleteItem,
  recurringDeleteItem,
  getNames,
  create,
  info,
  getTotalBalance,
  getVabankChartsData,
  getExport,
  getTags,
};
