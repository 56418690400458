import { createAction } from "typesafe-actions"

export const startApp = createAction('app/start')<void>()

export const markLoaded = createAction('app/start')<void>()

export type LoadedActionPayload = {
    payees: any,
    projects: any,
    moneyCategories: any,
    deals: any,
    people: any,
    projectGroups: any,
    urlFilters: any,
    tags: any
}

export const loaded = createAction('app/loaded')<LoadedActionPayload>()

export const loadTotalBalanceSuccess = createAction('totalBalance/loaded')<any>()

export const ready = createAction('app/ready')<void>()

export const appActions = {
    startApp,
    markLoaded,
    loaded,
    ready,
    loadBalanceSuccess: loadTotalBalanceSuccess
}
