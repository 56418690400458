import { Typography } from "@material-ui/core";
import Tooltip from "../../../Tooltip";
import { withStyles } from "@material-ui/styles";
import React from "react";
import MoneyItems from "../../../../service/MoneyItems";
import RepeatOneIcon from "@material-ui/icons/RepeatOne";
import moment from "moment";

const style = (theme: any) => ({
  root: {
    ...theme.components.iconButton,
    display: "flex",
    "justify-content": "center",
    "align-items": "center",
  },
  icon: {
    fill: theme.palette.action.active,
  },
});

export const TopParentIcon: React.FC<{
  childrenIds: any[];
  classes: Record<string, any>;
}> = ({ classes, childrenIds }) => {
  const [show, setShow] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any[]>([]);

  React.useEffect(() => {
    (async () => {
      if (data && data.length > 0 || !show) {
        return;
      }

      const newData = [];
      for (let i = 0; i < childrenIds.length; i++) {
        const response = await MoneyItems.getItem(childrenIds[i]);
        newData.push(response)
      }

      setData(newData);
    })();
  }, [data, show, childrenIds]);

  const hasChildren = childrenIds && childrenIds.length > 0;

  if (!hasChildren) {
    return <div className={classes.root} />;
  }

  const titleWithChildrenData =
    data.length > 0 ? (
      <div>
        <Typography variant="subtitle2">Children:</Typography>
        {data.map((child) => (
          <Typography>
            <>
              Date: {moment(child["transaction-on"]).format("DD/MM/YYYY")};
              Name: {child.name}
            </>
          </Typography>
        ))}
      </div>
    ) : (
      "Loading"
    );

  return (
    <Tooltip show={show} setShow={setShow} title={titleWithChildrenData}>
      <div className={classes.root}>
        <RepeatOneIcon className={classes.icon} />
      </div>
    </Tooltip>
  );
};

export default withStyles(style)(TopParentIcon);
