import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'

import { rootReducer } from './model/root.reducer'
import { rootSaga } from './model/root.saga'

import { composeWithDevTools } from 'redux-devtools-extension';

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware()
// Mount it on the Store
export const store = createStore(
    rootReducer,
    composeWithDevTools(
      applyMiddleware(sagaMiddleware)
    )
)

// Then run the saga
sagaMiddleware.run(rootSaga)

export type AppDispatch = typeof store.dispatch
