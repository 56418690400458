import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, withStyles } from '@material-ui/core';
import DragIndicator from '@material-ui/icons/DragHandle';

const style = theme => ({
	iconButton: theme.components.iconButton
})

function DragButton({ lock, classes, listeners }) {
	return (
		<IconButton disabled={lock} className={classes.iconButton} {...(lock ? {} : listeners)}>
			<DragIndicator />
		</IconButton>
	)
}

DragButton.propTypes = {
	lock: PropTypes.bool,
}

export default withStyles(style)(DragButton);
