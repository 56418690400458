import React, { useEffect, useState } from "react";
import {Grid, Typography} from "@material-ui/core";
import moment from "moment";
import Spiner from "../Spiner/Spiner";
import BarChart from "./BarChart";
import RealisticChart from "./RealisticChart";
import CumulativeChart from "./CumulativeChart";
import MoneyItems from "../../service/MoneyItems";
import { RootState } from "../../model/root.reducer";
import { useSelector } from "react-redux";

const CHART_BAR_HEIGHT = 10;
const CHART_X_LABEL_HEIGHT = 300;
const computeChartHeight = (items: any) =>
  items.length * CHART_BAR_HEIGHT + CHART_X_LABEL_HEIGHT;


const ChartView = () => {
  const datestart = useSelector((state: RootState) => state.filters.startOfMonth);
  const dateend = useSelector((state: RootState) => state.filters.endOfMonth);
  const payeeId = useSelector((state: RootState) => state.filters.payeeId);
  const projectId = useSelector((state: RootState) => state.filters.projectId);
  const moneyCategories = useSelector((state: RootState) => state.filters.moneyCategories);

  const currency = "PLN";
  const [financeChartsData, setFinanceChartsData] = useState<any>(null);
  const [realisticChartData, setRealiticChartData] = useState<any>(null);
  const [cumulativeChartData, setCumulativeChartData] = useState<any>(null);
  const [vatData, setVatData] = useState<any>(null);

  const loadChartsData = () => {
    if (!(dateend instanceof moment) || !(datestart instanceof moment)) {
      return;
    }

    MoneyItems.getVabankChartsData({
      date_from: datestart.format("DD/MM/YYYY"),
      date_to: dateend.format("DD/MM/YYYY"),
      payee_id: payeeId,
      project_id: projectId,
      moneyCategory_id: moneyCategories?.map((item) => item.value).join(','),
    }).then((response) => {
      setRealiticChartData(response.realistic_chart_data);
      setCumulativeChartData(response.cumulative_chart_data);
      setFinanceChartsData(response.finance_graphs_data);
      setVatData(response.vat_data);
    });
  };

  useEffect(loadChartsData, [datestart, dateend, payeeId, projectId, moneyCategories]);

  if (!financeChartsData || !realisticChartData || !vatData) {
    return <Spiner />;
  }

  return (
    <Grid container>
      <Grid container>
        <Grid item xs={12}>
          <RealisticChart currency={currency} chartData={realisticChartData} />
        </Grid>
        <Grid item xs={12}>
          <CumulativeChart
            currency={currency}
            chartData={cumulativeChartData}
          />
        </Grid>
        <Grid item xs={12}>
          <h2>Incomes</h2>
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <h4>By Payee</h4>
            <BarChart
              opts={{ maintainAspectRatio: false }}
              height={computeChartHeight(financeChartsData!.income.payees)}
              currency={currency}
              data={financeChartsData.income.payees}
              labelLength={15}
              abs={false}
            />
          </Grid>
          <Grid item xs={4}>
            <h4>By Projects</h4>
            <BarChart
              opts={{ maintainAspectRatio: false }}
              height={computeChartHeight(financeChartsData.income.projects)}
              currency={currency}
              data={financeChartsData.income.projects}
              labelLength={15}
              abs={false}
            />
          </Grid>
          <Grid item xs={4}>
            <h4>By Money Category</h4>
            <BarChart
              opts={{ maintainAspectRatio: false }}
              height={computeChartHeight(
                financeChartsData.income.money_categories
              )}
              currency={currency}
              data={financeChartsData.income.money_categories}
              abs={false}
              labelLength={null}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <h2>Outcomes</h2>
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <h4>By Payee</h4>
            <BarChart
              opts={{ maintainAspectRatio: false }}
              height={computeChartHeight(financeChartsData.outcome.payees)}
              currency={currency}
              data={financeChartsData.outcome.payees}
              labelLength={15}
              abs
            />
          </Grid>
          <Grid item xs={4}>
            <h4>By Projects</h4>
            <BarChart
              opts={{ maintainAspectRatio: false }}
              height={computeChartHeight(financeChartsData.outcome.projects)}
              currency={currency}
              data={financeChartsData.outcome.projects}
              labelLength={15}
              abs
            />
          </Grid>
          <Grid item xs={4}>
            <h4>By Money Category</h4>
            <BarChart
              opts={{ maintainAspectRatio: false }}
              height={computeChartHeight(
                financeChartsData.outcome.money_categories
              )}
              currency={currency}
              data={financeChartsData.outcome.money_categories}
              abs
              labelLength={null}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <h2>Balances</h2>
        </Grid>
        <Grid item xs={12}>
          <h4>By Payee</h4>
          <BarChart
            opts={{ maintainAspectRatio: false }}
            height={computeChartHeight(financeChartsData.balance.payees)}
            labelLength={15}
            currency={currency}
            data={financeChartsData.balance.payees}
            abs={false}
          />
        </Grid>
        <Grid item xs={12}>
          <h4>By Project</h4>
          <BarChart
            opts={{ maintainAspectRatio: false }}
            height={computeChartHeight(financeChartsData.balance.projects)}
            currency={currency}
            data={financeChartsData.balance.projects}
            abs={false}
            labelLength={null}
          />
        </Grid>
        <Grid item xs={12}>
          <h4>By Money Category</h4>
          <BarChart
            opts={{ maintainAspectRatio: false }}
            height={computeChartHeight(
              financeChartsData.balance.money_categories
            )}
            currency={currency}
            data={financeChartsData.balance.money_categories}
            abs={false}
            labelLength={null}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4}>
          <h2>VAT</h2>
          <Grid container component="dl" style={{display: 'flex', flexDirection: 'column'}}>
            <Grid item style={{display: 'flex', flexDirection: 'row'}}>
              <Typography component="dt" variant="h6">
                Incomes:
              </Typography>
              <Typography component="dd" variant="body1" style={{marginLeft: 3, fontSize: '1.25rem', lineHeight: 1.6}}>
                {vatData.incomes}
              </Typography>
            </Grid>
            <Grid item style={{display: 'flex', flexDirection: 'row'}}>
              <Typography component="dt" variant="h6">
                Costs:
              </Typography>
              <Typography component="dd" variant="body1" style={{marginLeft: 3, fontSize: '1.25rem', lineHeight: 1.6}}>
                {vatData.costs}
              </Typography>
            </Grid>
            <Grid item style={{display: 'flex', flexDirection: 'row'}}>
              <Typography component="dt" variant="h6">
                Difference:
              </Typography>
              <Typography component="dd" variant="body1"  style={{marginLeft: 3, fontSize: '1.25rem', lineHeight: 1.6}}>
                {vatData.difference}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChartView;
