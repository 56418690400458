import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import * as Sentry from '@sentry/browser';
import config from './config'
import { Provider } from 'react-redux';
import { store } from './store';


const sentryURL = config.sentryURL;

if (process.env.NODE_ENV === 'production') {
	Sentry.init({ dsn: sentryURL });
}

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));
registerServiceWorker();
