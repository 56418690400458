import React from "react";
import Snackbar from "material-ui/Snackbar";

let openSnackbarFn;

const Notifier = () => {
  const [open, setOpen] = React.useState(false);
  const [notifications, setNotifications] = React.useState([]);
  const notification = notifications.length ? notifications[0] : null;

  React.useEffect(() => {
    openSnackbarFn = (message) => {
      setNotifications((prevNotitications) => prevNotitications.concat(message));
    };
  }, []);

  React.useEffect(() => {
    setOpen(!!notification);
  }, [notification]);

  const handleRequestClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const handleExited = React.useCallback(() => {
    setNotifications((prevNotitications) => prevNotitications.slice(1));
  }, []);

  return (
    <Snackbar
      anchororigin={{ vertical: "bottom", horizontal: "center" }}
      message={notification}
      autoHideDuration={3000}
      onClose={handleRequestClose}
      onExited={handleExited}
      open={open}
    />
  );
};

export function openSnackbar(message) {
  openSnackbarFn(typeof message === 'string' ? message : message.message);
}

export default Notifier;
