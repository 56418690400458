import moment from "moment";
import { MoneyItemEntity } from "../../../model/reducers/moneyItems/MoneyItemEntity";

export const getColor = (cashItem: MoneyItemEntity) => {
  const compare = moment(cashItem.transactionOn);
  const today = moment(new Date()).format("YYYY-MM-DD");
  let color = "transparent";
  // if (compare.isSame(today) && cashItem['paid'] === false) {
  // 	color = "blue";
  // }
  // if (compare.isBefore(today) && cashItem['paid'] === false) {
  // 	color = "yellow";
  // }
  if (!Boolean(cashItem.payeeId) ||
    !Boolean(cashItem.moneyCategoryId)) {
    color = "yellow";
  }
  if (!Boolean(cashItem.projectId)) {
    color = "lightyellow";
  }
  if (compare.isSame(today)) {
    color = "blue";
  }
  return color;
};
