import { SagaIterator } from 'redux-saga';
import { all, delay, put, takeEvery } from 'redux-saga/effects';
import { toastsActions } from './toasts.actions';

export function* showToastSaga(action: ReturnType<typeof toastsActions.show>): SagaIterator<any> {
  if (action.payload.duration > -1) {
    yield delay(action.payload.duration - 500);

    yield put(toastsActions.prepareHiding(action.payload.id));

    yield delay(500);

    yield put(toastsActions.hide(action.payload.id));
  }
}

export function* toastsSaga(): SagaIterator<any> {
  yield all([
    takeEvery(toastsActions.show, showToastSaga)
  ]);
}
