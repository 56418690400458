import { all, call, spawn } from 'redux-saga/effects'
import { toastsSaga } from '../toasts/toasts.saga';
import { appSaga } from './sagas/app.saga';
import { payeeSaga } from './sagas/payees.saga';

export function* rootSaga() {
    const sagas = [
        appSaga,
        toastsSaga,
        payeeSaga
    ];
  
    yield all(sagas.map(saga =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga)
            break
          } catch (e) {
            console.log(e)
          }
        }
      }))
    );
  }