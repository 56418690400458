import { createAction } from "typesafe-actions"
import { JsonApiResponse } from "../../common/JsonApiResponse"
import { WithId } from "../../common/withId"
import { CreatePayeeInputs } from "../../components/CreatePayee/CreatePayee"
import { PayeeApiEntity } from "../reducers/payees/PayeeApiEntity"

const loadSuccess = createAction('payees/loadSuccess')<JsonApiResponse<PayeeApiEntity>>()

const createPayee = createAction('payees/createPayee')<WithId<CreatePayeeInputs>>()

const updateResults = createAction('payees/updateResults')<any>()

export const payeesActions = {
    loadSuccess,
    createPayee,
    updateResults
}
