import React from "react";
import { BrowserRouter as Router, Route, Redirect, useHistory, useLocation } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import withAuthentication from "./withAuthentication";
import * as routes from "./constants/routes";
import Notifier from "./components/Notifier/Notifier";
import { MuiThemeProvider as MuiThemeProviderV0 } from "material-ui/styles";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import UserProvider from "./provider/UserProvider";
import config from "./config";
import './App.css';
import { useEffect } from "react";
import { appActions } from "./model/actions/app.actions";
import { useDispatch, useSelector } from "react-redux";
import { Routes } from "./components/Routes/Routes";

const sentryURL = config.sentryURL;

const theme = createTheme({
  props: {
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
  components: {
    iconButton: {
      width: 36,
      height: 36,
    },
  },
  overrides: {
    MuiIconButton: {
      root: {
        width: 36,
        height: 36,
      },
    },
    // Name of the component ⚛️
    MuiCssBaseline: {
      // Name of the rule
      '@global': {
        '*, *::before, *::after': {
          transition: 'none !important',
          animation: 'none !important',
        },
      },
    },
  },
  transitions: {
    // So we have `transition: none;` everywhere
    create: () => 'none',
  },
} as any);

if (process.env.NODE_ENV === "production") {
  Sentry.init({ dsn: sentryURL });
}

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appActions.startApp());
  }, [dispatch]);

  return (
    <UserProvider>
      <MuiThemeProvider theme={theme}>
        <MuiThemeProviderV0>
          <Router>
            <div>
              <Notifier />
              <Routes />
            </div>
          </Router>
        </MuiThemeProviderV0>
      </MuiThemeProvider>
    </UserProvider>
  );
};

export default withAuthentication(App);
