import React from "react";
import AutoCompleteInputWrapper from "../../../AutocompleteInput/AutoCompleteInputWrapper";
import { useHistory } from "react-router-dom";

export const AutocompleteInput: React.FC<any> = React.memo(
  ({
    id,
    className,
    value: valueProp = "",
    choices,
    placeholder,
    disabled,
    onChange,
    onCreateNewItem,
    onInputChange,
    clearable,
  }) => {
    const [value, setValue] = React.useState(valueProp);

    React.useEffect(() => {
      setValue(valueProp);
    }, [valueProp]);

    const history = useHistory();

    const handleSelectLabel = (newValue: any) => {
      if (!!newValue && newValue.startsWith("go-to-url-")) {
        const url = newValue.replace("go-to-url-", "");

        history.push(url);

        return;
      }

      setValue(newValue);

      onChange(newValue);
    };

    return (
      <>
        <div className={className} style={{ marginRight: 2 }}>
          <AutoCompleteInputWrapper
            onInputChange={onInputChange}
            onCreateNewItem={onCreateNewItem}
            value={value}
            id={id}
            // selectItems={choices}
            fetchDataFunction={() => choices}
            onSelectLabel={handleSelectLabel}
            placeholder={placeholder}
            disabled={disabled}
            textLength={12}
            clearable={clearable}
          />
        </div>
      </>
    );
  }
);

export default AutocompleteInput;
