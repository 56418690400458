import React from "react";
import TextInput from "./TextInput";
import { useEffect } from "react";

export type Props = {
    id: string;
    isInheritedParent: boolean;
    inheritedParentId: string;
    name: string;
    disabled: boolean;
    className?: string;
    value: string;
    onBlur: Function
};

const NameInput: React.FC<Props> = React.memo(
    ({
        id,
        disabled,
        className,
        value: valueProp = "",
        onBlur
    }) => {
        const [value, setValue] = React.useState(valueProp);

        useEffect(() => {
            setValue(valueProp);
        }, [valueProp]);

        const handleBlur = () => {
            if (value.length < 1) {
                alert("Invalid value - reverting to the original!");
                setValue(valueProp);
            } else {
                // setOpen(true);
                onBlur(value)
            }
        };

        const handleChange = (event: any) => {
            setValue(event.target.value);
        };

        return (
            <>
                <TextInput
                    className={className}
                    value={value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={disabled}
                />
            </>
        );
    }
);

export default NameInput;
