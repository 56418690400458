import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withRouter } from "react-router-dom";
import * as routes from "../constants/routes";
import { openSnackbar } from "./Notifier/Notifier";
import AuthService from "../service/AuthService";
const SignOutPage = ({ history }) => (
  <div>
    <SignOutButton history={history} />
  </div>
);

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});
class SignOutButton extends Component {
  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleLogout = () => {
    const { history } = this.props;

    AuthService.logout()
      .then(() => {
        openSnackbar({ message: "Logged Out" });
        history.push(routes.SIGN_IN);
      })
      .catch((error) => {
        this.setState(byPropKey("error", error));
      });
  };
  render() {
    const { anchorEl } = this.state;
    return (
      <>
        <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-label="menu"
          aria-controls="signout-menu"
          onClick={this.handleClick}
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id="signout-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
        </Menu>
      </>
    );
  }
}

export default withRouter(SignOutPage);

export { SignOutButton };
