import React from "react";
import { withStyles, Collapse } from "@material-ui/core";
import PropTypes from "prop-types";

const style = (theme) => ({
  root: {
    position: "relative",
  },
  tooltipRoot: {
    position: "absolute",
    background: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    zIndex: theme.zIndex.tooltip,
    width: "50vh",
    ...theme.shape,
  },
  tooltip: {
    fontSize: 14,
    padding: theme.spacing(1),
    minWidth: 200,
  },
});

function Tooltip({ classes, title, children, show, setShow }) {
  return (
    <div
      className={classes.root}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {children}
      <div className={classes.tooltipRoot}>
        <Collapse in={show} className={classes.collapse}>
          <div className={classes.tooltip}>{title}</div>
        </Collapse>
      </div>
    </div>
  );
}

Tooltip.propTypes = {
  classes: PropTypes.any,
};

export default withStyles(style)(Tooltip);
