import Tooltip from "@material-ui/core/Tooltip";
import { IconButton } from "material-ui";
import React, { useState } from "react";
import { CalculatorIcon } from "./CalculatorIcon";
import { CalculatorModal, CalculatorModalInput } from './CalculatorModal';

type Props = {
  amountBank: string
  amountCalculation: string
  onChange: (input: CalculatorModalInput) => void
  disabled: boolean
  isNetto: boolean
}

export const Calculator: React.FC<Props> = ({
  amountBank,
  amountCalculation,
  onChange,
  disabled,
  isNetto
}) => {
  const onCalculatorOpenClick = () => {
    if (disabled) {
      return false;
    }

    setIsOpen(true)
  }

  const [isOpen, setIsOpen] = useState(false);

  const iconColor = getIconColor(amountBank, amountCalculation, disabled)
  const tooltipTitle = getTooltipTitle(amountBank, amountCalculation)

  const onSuccess = (input: CalculatorModalInput) => {
    setIsOpen(false)

    onChange(input)
  }
  
  return <>
    <Tooltip title={tooltipTitle}>
      <IconButton style={{ padding: 0, width: '1.7rem', height: '1.7rem', marginTop: 3, marginLeft: 6, fill: iconColor }}>
        <CalculatorIcon onClick={onCalculatorOpenClick} style={{ fill: iconColor, cursor: !disabled ? 'pointer' : '' }} />
      </IconButton>
    </Tooltip>
    {isOpen && <CalculatorModal isNetto={isNetto} amountBank={amountBank} amountCalculation={amountCalculation} onSuccess={onSuccess} onClose={() => setIsOpen(false)} />}
  </>;
};

function getIconColor(amountBank: string, amountCalculation: string, disabled: boolean) {
  if (disabled) {
    return 'gray';
  }

  return parseFloat(amountBank) === parseFloat(amountCalculation) ? 'black' : 'green';
}

function getTooltipTitle(amountBank: string, amountCalculation: string) {
  if (parseFloat(amountBank) !== parseFloat(amountCalculation)) {
    return <h2>Formula: {amountCalculation}</h2>
  }

  return ''
}

