import React from 'react'
import {HorizontalBar} from 'react-chartjs-2'
import { moneyFormat } from './formats';

const CumulativeChart = ({ currency, chartData }) => {

    const options = {
        legend: {
            display: false
        },
        tooltips: {
            callbacks: {
                label: (tooltipItem, data) => moneyFormat(tooltipItem.xLabel, currency),
            }
        },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            xAxes: [{
                ticks: {
                    beginAtZero: true,
                    userCallback: (value) => moneyFormat(value, currency),
                },
            }]
        },
    }
  const data = {
      labels: ['Outcomes', 'Incomes', 'Difference'],
    datasets: [
      {
          borderWidth: 1,
          data: [chartData.outcome, chartData.income, chartData.difference],
          backgroundColor: ['red', 'green', 'blue']
      }
    ]
  };

    return (
        <div>
            <h2>Cumulative</h2>
            <HorizontalBar data={data} options={options} height={200} />
        </div>
    );
}

export default CumulativeChart
