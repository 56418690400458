import MoneyItems from '../service/MoneyItems';
import HookRequest from '../service/HookRequest';

export const updateCashItem = async (cashItem, updateElements, tags = "", action) => {
	const parentId = cashItem['inherited-parent-id'];
	const amount = updateElements['amount-bank'] ?
		updateElements['amount-bank'] :
		cashItem['amount-bank']

	await MoneyItems.update({
		id: cashItem.id,
		tags,
		...updateElements,
	})
	
	if (action !== null) {
		const parent = await MoneyItems.getItem(parentId);
		HookRequest({
			name: cashItem.name,
			value: amount,
			parent: parent ? parent.name : undefined,
		}, action)
	}
}
