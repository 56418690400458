import React from 'react'
// import PropTypes from 'prop-types'
import { IconButton, withStyles } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';

const style = theme => ({
	iconButton: theme.components.iconButton
})

function SettingsButton({ classes, onClick, disabled }) {
	return (
		<IconButton
			className={classes.iconButton}
			onClick={onClick}
			disabled={disabled}
		>
			<SettingsIcon />
		</IconButton>
	)
}

SettingsButton.propTypes = {
}

export default withStyles(style)(SettingsButton);
