import _ from "lodash";
import { MoneyItemsState } from "./moneyItems.reducer";

export function calculateDummyBalance(state: MoneyItemsState) {
    const sortedByTransactionOnAndAmountBank = Object.values(state).sort((a, b) => {
        if (a.transactionOn < b.transactionOn) {
          return -1;
        } else if (a.transactionOn > b.transactionOn) {
          return 1;
        }
    
        const amountABankNumber = parseFloat(a.amountBank);
        const amountBBankNumber = parseFloat(b.amountBank);
    
        if (amountABankNumber < amountBBankNumber) {
          return -1;
        } else if (amountABankNumber > amountBBankNumber) {
          return 1;
        }
    
        return 0;
    });
  
    let items = sortedByTransactionOnAndAmountBank;

    for (let i = 0; i < items.length; i++) {
      const index = i;

      const prevItem = Boolean(sortedByTransactionOnAndAmountBank[index - 1]) ? sortedByTransactionOnAndAmountBank[index - 1] : null
      const currItem = items[index]
  
      if (!prevItem) {
        items[index] = {
          ...currItem,
          vabankBalance: (currItem.previousBalance + parseFloat(currItem.amountBank)).toFixed(2)
        }

        continue;
      }
  
      items[index] = {
        ...currItem,
        vabankBalance: (parseFloat(prevItem.vabankBalance) + parseFloat(currItem.amountBank)).toFixed(2)
      }
    }
  
    const sortedWithPrevious = items.map((item, index) => {
      const currItem = item
  
      return {
        ...currItem,
        order: index + 1,
        previousBalance: parseFloat(item.vabankBalance) - parseFloat(item.amountBank)
      }
    })
  
    return _.keyBy(sortedWithPrevious, 'id'); 
};
