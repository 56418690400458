import { createAction } from "typesafe-actions";

let autoIncrement = 0

export type ToastType = 'success' | 'error' | 'warning' | 'info';

const show = createAction('toasts/show', (message: string, type: ToastType, duration: number = 5000) => {
  return {
    id: (autoIncrement++).toString(),
    message,
    type,
    duration,
    isHiding: false
  };
})();

const prepareHiding = createAction('toasts/prepareHiding', (id: string) => {
  return {
    id
  };
})();

const hide = createAction('toasts/hide', (id: string) => {
  return {
    id
  };
})();

export const toastsActions = {
  show,
  hide,
  prepareHiding
};
