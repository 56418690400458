import React from "react";

export const CalculatorIcon = ({ ...props }) => (
  <svg
    {...props}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 330 330"
    xmlSpace="preserve"
  >
    <g id="XMLID_231_">
      <path
        id="XMLID_232_"
        d="M270.102,0H59.898c-8.284,0-15,6.716-15,15v90v210c0,8.284,6.716,15,15,15h210.204c8.284,0,15-6.716,15-15
		V105V15C285.102,6.716,278.386,0,270.102,0z M144.898,230v-40.001h40.001V230H144.898z M184.899,260v40h-40.001v-40H184.899z
		 M184.899,120v39.999h-40.001V120H184.899z M255.102,120v39.999h-40.203V120H255.102z M114.898,159.999H74.898V120h39.999V159.999z
		 M74.898,189.999h39.999V230H74.898V189.999z M214.899,189.999h40.203V230h-40.203V189.999z M255.102,30v60H74.898V30H255.102z
		 M74.898,260h39.999v40H74.898V260z M214.899,300v-40h40.203v40H214.899z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);
