/**
 * @param Params like: name, value, parent
 * @param action One of: ['new', 'edit', 'delete', 'paid']
 */

export type WebhookActionType = 'new' | 'edit' | 'delete' | 'paid'

const HookRequest = async ({ name, value, parent }: any, action: WebhookActionType = 'edit') => {
	const userEmail =  localStorage.getItem("user-email");

	try {
		let xhr = new XMLHttpRequest();
		xhr.open("POST", "https://chat.unitedideas.co/hooks/o7isvQZT2PQHMCWA3/XYpbCRoasgYnMSwtsDwPCBGRppPTFNhG6KQsnnx75MGTFYEJ");
		xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
		var parentText = parent ? parent : 'not set';
		xhr.send(JSON.stringify({
			username: "VaBank",
			icon_emoji: ":money_with_wings:",
			text: "User `" + userEmail + "` took action `" + action + "` for item `" + name  + "` and value `" + value + "` (parent is `" + parentText + "`)"
		}))
	} catch (error) {
	}
}

export default HookRequest;