import React, { useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import AddPayment from "../AddPayment/AddPayment";
import Acceptbox from "../Acceptbox/Acceptbox";
import { SortableList } from "../SortableList/SortableList";
import Spiner from "../Spiner/Spiner";
import { selectMoneyItems } from "../../model/selectors/moneyItems.selector";
import { useRef } from "react";
import { selectPayees } from "../../model/selectors/payees.selector";
import { selectMoneyCategories } from "../../model/selectors/moneyCategories.selector";
import { selectProjects } from "../../model/selectors/projects.selector";
import { FabAddition } from "./FabAddition";
import { RootState } from "../../model/root.reducer";

export const GridView: React.FC<any> = () => {
  const items = useSelector(selectMoneyItems);

  const projects = useSelector(selectProjects);

  const payees = useSelector(selectPayees);

  const moneyCategories = useSelector(selectMoneyCategories);

  const loading = useSelector((state: RootState) => !state.app.isReady);

  const child = useRef();

  let addPayment = (
    <div>
      <div>
        <FabAddition />
      </div>
      <AddPayment
        ref={child}
        asc_index={"ASC"}
        payees={payees}
        projects={projects}
        moneyCategories={moneyCategories}
      />
    </div>
  );

  const random = useMemo(() => Math.ceil(Math.random() * 6), []);

  return (
    <div className="person-list">
      {loading ? (
        random === 1 ? (
          <img
            style={{ marginLeft: "auto", marginRight: "auto" }}
            src="/betoniarka.gif"
          />
        ) : (
          <Spiner />
        )
      ) : null}

      <Acceptbox />
      <div className="page-padding">
        <SortableList
          items={items}
          moneyCategories={moneyCategories}
          projects={projects}
          payees={payees}
        />
      </div>
      <div className="addPaymentBtn">{addPayment}</div>
      {/* <div>
      <WarningEditOldCashItem
        isOpen={this.state.editWarningOpen}
        onAccept={this.handleAcceptWarningOnEditCashItem}
        onClose={() => {
          this.setStateValue("editWarningOpen", false);
          (this as any).__popupRejectFunc();
        }}
      />
    </div> */}
    </div>
  );
};
