import { createReducer } from "typesafe-actions";
import { denormalizeCollectionResponse } from "../../../common/denormalizeResponse";
import { WithId } from "../../../common/withId";
import { appActions } from "../../actions/app.actions";
import { peopleActions } from "../../actions/people.actions";
import { PersonApiEntity } from "./PersonApiEntity";

export type PeopleState = {
  [key: string]: WithId<PersonApiEntity>;
};

export const peopleReducer = createReducer<PeopleState>({}).handleAction(
  appActions.loaded,
  (
    state: PeopleState,
    action: ReturnType<typeof appActions.loaded>
  ) => {
    const result = denormalizeCollectionResponse(action.payload.people);

    return result;
  }
);
