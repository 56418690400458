import moment from 'moment'
import HookRequest from './HookRequest';
import { updateCashItem } from '../actions/updateCashItem';
import MoneyItems from './MoneyItems';

const mapMoneyToNumber = (value) => {
	return parseFloat(value.replace(/ /gm, ''));
}

export default async (values, tags, {
	setError,
	clearValues,
	handleCloseDialog,
}, {
	edit,
	isDuplicating = false,
	parentName
}) => {
	let { payRepeat, ...otherValues } = values
	let state = {
		...otherValues,
		"cost-on": otherValues.transactionOn,
		"income-on": otherValues.transactionOn,
		"genre": otherValues.virtual,
		"amount-bank": mapMoneyToNumber(otherValues.amountBrutto),
	};

	const prepareObjName = (obj) => {
		let tempObj = {}
		Object.keys(obj).forEach((item) => {
			let newKey = ""
			for (const char in item) {
				if (item[char] === item[char].toUpperCase() && item[char] !== '-') {
					newKey += "-" + item[char].toLowerCase()
				}
				else newKey += item[char]
			}
			tempObj[newKey] = obj[item]
			if (newKey === 'amount-brutto' || newKey === 'amount-vat' || newKey === 'amount-netto') {
				tempObj[newKey] = mapMoneyToNumber(obj[item]);
			}
		})
		return tempObj
	}

	if (state.moneyAccountId === null) {
		setError('moneyAccountId', 'Can not be empty')
		return false;
	}

	if (state.transactionOn === '') {
		setError('transactionOn', 'Date can not be empty')
		return false;
	}

	if (state.name === '') {
		setError('name', 'Can not be empty')
		return false;
	}

	if (state.amountNetto === '') {
		setError('amountNetto', 'Can not be empty')
		return false;
	}

	if (state.amountVat === '') {
		setError('amountVat', 'Can not be empty')
		return false;
	}

	if (state.amountBrutto === '') {
		setError('amountBrutto', 'Can not be empty')
		return false;
	}

	if (!state.isPayed && payRepeat <= 0 && payRepeat !== '') {
		setError('payRepeat', 'Must be higher than 0')
		return false;
	}

	if (payRepeat !== '' && payRepeat > 0) {
		let month = 0
		if (state.inheritedParentId === '') {
			month = 1;
			await MoneyItems.create(prepareObjName(state))
		}

		let repeatedItems = [];
		for (let i = month; i < payRepeat; i++) {
			var futureMonth = moment(state.transactionOn).add(i, 'M').format('YYYY-MM-DD');
			repeatedItems.push({
				...state,
				transactionOn: futureMonth,
			})
		}

		let fisrtElement = repeatedItems.shift()

		let firstParentId = await MoneyItems.create(prepareObjName(fisrtElement))

		repeatedItems.forEach(async (item) => {
			await MoneyItems.create({
				...prepareObjName(item),
				'inherited-parent-id': parseInt(firstParentId)
			})
		})
		clearValues()

		handleCloseDialog();
	}
	else {
		if (edit && !isDuplicating) {
			await updateCashItem(
				prepareObjName(state),
				prepareObjName({ ...state, id: edit }),
				tags, 
				"edit")
		}
		else {
			await MoneyItems.create(prepareObjName(state), tags)
			HookRequest({
				name: state.name,
				value: state.amountBank,
				parent: parentName,
				tags
			}, 'new')
		}

		clearValues()

		handleCloseDialog();
	}

	return true;
}