import React from 'react';

import AuthUserContext from './AuthUserContext';

const withAuthentication = (Component) =>
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

	  let userData = localStorage.getItem('user');
		userData = userData ? JSON.parse(userData) : null;
	 
      this.state = {
        authUser: userData,
      };
    }


    render() {
      const { authUser } = this.state;

      return (
        <AuthUserContext.Provider value={authUser}>
          <Component />
        </AuthUserContext.Provider>
      );
    }
  }

export default withAuthentication;