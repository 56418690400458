import React, { useState } from 'react'
import AutocompleteInput from './AutocompleteInput';
import debounce from 'lodash/debounce';
import LinearProgress from '@material-ui/core/LinearProgress';

export const AutoCompleteInputWrapper: React.FC<any> = ({
	value,
	onSelectLabel,
	fetchDataFunction,
	selectId,
	placeholder,
	error,
	state,
	filter = false,
	selectItems,
	disabled = false,
	textLength,
	id,
	onCreateNewItem,
	onInputChange,
	multi,
	tags,
	clearable,
	removeTagFromSelectedTags
}) => {
	const [search, setSearch] = useState('')
	const [items, setItems] = React.useState([])
	const [loaded, setLoaded] = React.useState(Array.isArray(selectItems))
	
	const fetchData = React.useCallback(async () => {
		const newItems = await fetchDataFunction()
		setLoaded(true)
		setItems(newItems)
	}, [fetchDataFunction])
	
	React.useEffect(() => {
		if (typeof fetchDataFunction === 'function' && !Array.isArray(selectItems)) {
			fetchData()
		}
	}, [fetchData, fetchDataFunction, selectItems])

	const allItems = Array.isArray(selectItems) ? selectItems : items
	let filteredItems = [...allItems]
	
	if (filter) {
		filteredItems = filteredItems.filter((item) => item.parentId === null)
	}
	
	if (search.length && filteredItems?.length) {
		filteredItems = filteredItems
			.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1)
	}
	
	const debouncedSetSearch = React.useMemo(() => debounce(setSearch, 200), [])
	
	if (!loaded) {
		return <LinearProgress />
	}

	let options = filteredItems;

	if (clearable) {
		options.unshift({ id: '', name: '- Clear -' });
	}
	
	return <AutocompleteInput
		onCreateNewItem={onCreateNewItem}
		onInputChange={onInputChange}
		value={value}
		items={options}
		onSelectLabel={onSelectLabel}
		setSearch={debouncedSetSearch}
		selectId={selectId}
		placeholder={placeholder}
		error={error}
		stateOuter={state}
		disabled={disabled}
		textLength={textLength}
		id={id}
		multi={multi}
		tags={tags}
		clearable={clearable}
		removeTagFromSelectedTags={removeTagFromSelectedTags}
	/>
}
 

export default AutoCompleteInputWrapper;
