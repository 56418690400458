import jsonApiNormalizer from "json-api-normalizer";
import { camelCase, mapKeys, mapValues } from "lodash";

export const denormalizeCollectionResponse = (response: any) => {
  const afterDenormalization = jsonApiNormalizer({
    data: response.map((item: any, index: number) => ({
      ...item,
      attributes: {
        ...item.attributes,
        order: index,
      },
    })),
  });

  const keyName = Object.keys(afterDenormalization)[0];

  const objects = afterDenormalization[keyName];

  return mapValues(objects, (item) => {
    return {
      id: item.id,
      ...item.attributes,
    };
  });
};

export const denormalizeEntityResponse = (response: any) => {
  return {
    id: response.data.id,
    ...mapKeys(response.data.attributes, (_value, key) => camelCase(key))
  }
};
