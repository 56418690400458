import config from "../config";

const apiBase = config.apiURL;

export const login = `${apiBase}/login`;
export const moneyItems = `${apiBase}/money-items`;
export const payees = `${apiBase}/payees`;
export const projects = `${apiBase}/lite-projects`;
export const projectsV2 = `${apiBase}/projects`;
export const projectGroups = `${apiBase}/project-groups`;
export const people = `${apiBase}/people`;
export const deals = `${apiBase}/deals`;
export const moneyAccounts = `${apiBase}/money-accounts`;
export const moneyCategory = `${apiBase}/money-categories`;
export const syncRuns = `${apiBase}/sync-runs`;
export const auditTrails = `${apiBase}/audit-trails`
export const tags = `${apiBase}/money-items/tags`

export default {
  login,
  moneyItems,
  payees,
  projects,
  moneyAccounts,
  moneyCategory,
  syncRuns,
  projectsV2,
  projectGroups,
  people,
  deals,
  auditTrails,
  tags
};
