import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  progress: {
    margin: theme.spacing(2),
  },
});
function Spiner(props) {
    const { classes } = props;
    return (
      <div className="Spiner">
        <CircularProgress className={classes.progress} />
      </div>
    );
  }
  
  Spiner.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  export default withStyles(styles)(Spiner);
