import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CancelIcon from "@material-ui/icons/Cancel";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ClearIcon from "@material-ui/icons/Clear";
import Chip from "@material-ui/core/Chip";
import Select from "react-select";
import moment from "moment";
import "react-select/dist/react-select.css";

class Option extends React.Component {
  handleClick = (event) => {
    this.props.onSelect(this.props.option, event);
  };

  render() {
    const { children, isFocused, isSelected, onFocus } = this.props;

    return (
      <MenuItem
        onFocus={onFocus}
        selected={isFocused}
        onClick={this.handleClick}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
          fontSize: 13,
          whiteSpace: "pre-wrap",
          height: "unset",
        }}
      >
        {children}
      </MenuItem>
    );
  }
}

function truncateString(str, num) {
  if (num == null || num == 0 || str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
}

function SelectWrapped(props) {
  const { classes, disabled, textLength, inputRef, onChange, ...other } = props;
  
  return (
    <Select
      ref={inputRef}
      disabled={disabled}
      optionComponent={Option}
      scrollMenuIntoView={false}
      onChange={value => {
        onChange({ target: { value } });
      }}
      noResultsText={
        <Typography
          onClick={() => {
            if (props.onCreateNewItem) {
              props.onCreateNewItem();
            }
          }}
        >
          {props.onCreateNewItem ? "+ Create new" : "No results"}
        </Typography>
      }
      arrowRenderer={(arrowProps) => {
        return arrowProps.isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
      }}
      clearRenderer={() => <ClearIcon style={{ minWidth: 16}} />}
      valueComponent={(valueProps) => {
        const { value, children, onRemove } = valueProps;
        const onDelete = (event) => {
          event.preventDefault();
          event.stopPropagation();
          onRemove(value);
        };

        if (onRemove) {
          return (
            <Chip
              tabIndex={-1}
              label={children}
              className={classes.chip}
              deleteIcon={<CancelIcon onTouchEnd={onDelete} />}
              onDelete={onDelete}
              style={{ margin: "0 2px", height: "100%" }}
            />
          );
        }

        return (
          <div className="Select-value">
            {truncateString(children, textLength)}
          </div>
        );
      }}
      {...other}
    />
  );
};

const ITEM_HEIGHT = 48;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  chip: {
    margin: theme.spacing(1 / 4),
  },
  // We had to use a lot of global selectors in order to style react-select.
  // We are waiting on https://github.com/JedWatson/react-select/issues/1679
  // to provide a much better implementation.
  // Also, we had to reset the default style injected by the library.
  "@global": {
    ".Select-control": {
      display: "flex",
      alignItems: "center",
      border: 0,
      height: "unset",
      background: "transparent",
      "&:hover": {
        boxShadow: "none",
      },
    },
    ".Select-multi-value-wrapper": {
      flexGrow: 1,
      display: "flex",
      flexWrap: "wrap",
    },
    ".Select--multi .Select-input": {
      margin: 0,
    },
    ".Select.has-value.is-clearable.Select--single > .Select-control .Select-value":
      {
        padding: 0,
        fontSize: 13,
      },
    ".Select-noresults": {
      padding: theme.spacing(2),
    },
    ".Select-input": {
      display: "inline-flex !important",
      padding: 0,
      height: "auto",
    },
    ".Select-input input": {
      background: "transparent",
      border: 0,
      padding: 0,
      cursor: "default",
      display: "inline-block",
      fontFamily: "inherit",
      fontSize: "inherit",
      margin: 0,
      outline: 0,
    },
    ".Select-placeholder, .Select--single .Select-value": {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      alignItems: "center",
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.pxToRem(16),
      padding: 0,
    },
    ".Select-placeholder": {
      opacity: 0.42,
      color: theme.palette.common.black,
      fontSize: 13,
    },
    ".Select-menu-outer": {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
      position: "absolute",
      left: 0,
      top: `calc(100% + ${theme.spacing()}px)`,
      width: "100%",
      zIndex: 4,
      maxHeight: ITEM_HEIGHT * 4.5,
    },
    ".Select.is-focused:not(.is-open) > .Select-control": {
      boxShadow: "none",
    },
    ".Select-menu": {
      maxHeight: ITEM_HEIGHT * 4.5,
      overflowY: "auto",
    },
    ".Select-menu div": {
      boxSizing: "content-box",
    },
    ".Select-arrow-zone, .Select-clear-zone": {
      color: theme.palette.action.active,
      cursor: "pointer",
      height: 21,
      width: 21,
      zIndex: 1,
    },
    // Only for screen readers. We can't use display none.
    ".Select-aria-only": {
      position: "absolute",
      overflow: "hidden",
      clip: "rect(0 0 0 0)",
      height: 1,
      width: 1,
      margin: -1,
    },
    ".Select.is-disabled > .Select-control": {
      backgroundColor: "unset",
    },
  },
});

class AutocompleteInput extends React.Component {
  state = {
    single: null,
    id: null,
  };

  inputRef = React.createRef();
  inputValue = React.createRef('');

  createSugesstionArray = () => {
    let suggestionArr = [];
    if (this.props.items !== undefined) {
      this.props.items.map((item) => {
        if (!item) {
          return;
        }
        suggestionArr.push({
          id: item.id,
          label: item.name,
          date: item.date ? item.date : null,
        });
      });
      suggestionArr = suggestionArr.map((suggestion) => ({
        value: suggestion.id,
        // label: `${moment(suggestion.date).format('DD/MM/YYYY')} ${suggestion.label} ${suggestion.realisticVal}`,
        label:
          suggestion.date === null
            ? suggestion.label
            : `${moment(suggestion.date).format("DD/MM/YYYY")} ${
                suggestion.label
              }`,
      }));
      this.setState({
        items: suggestionArr,
      });
    }
  };

  componentDidMount() {
    this.createSugesstionArray();
    this.setState({
      ...this.state,
      single: this.props.value,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.createSugesstionArray();
    }
    if (
      typeof this.props.value !== "undefined" &&
      prevProps.value !== this.props.value
    ) {

      this.setState({
        ...this.state,
        single: this.props.value,
      });
    }
  }

  handleChange = (name) => (event) => {
    const value = event.target.value;
    const currentItem = this.props.items.find((x) => x.id === value);
    
    this.props.onSelectLabel(value, currentItem, this.props.selectId);
    
    if (Boolean(this.props.stateOuter)) {
      delete this.props.stateOuter.error[this.props.selectId];
    }

    this.setState({
      [name]: value,
    });
  };

  handleInputChange = (currentValue) => {
    this.props.setSearch(currentValue);
    this.inputValue.current = currentValue;

    this.props.onInputChange && this.props.onInputChange(currentValue);
  };

  newTagCreation = () => {
    this.props.onCreateNewItem && this.props.onCreateNewItem(this.inputValue.current);
    // this.inputRef.current.ref.current.setInputValue('');
  };

  render() {
    const { classes, tags, removeTagFromSelectedTags } = this.props;
    return (
      <div className={classes.root} style={{ height: "unset" }}>
        {tags &&
          tags.map((tag) => {
            const onDelete = (event) => {
              event.preventDefault();
              event.stopPropagation();
              removeTagFromSelectedTags(tag)
            };
            return (
            <Chip
              tabIndex={-1}
              label={tag}
              // className={classes.chip}
              deleteIcon={<CancelIcon onTouchEnd={onDelete} />}
              onDelete={onDelete}
              style={{ margin: "0 2px", height: "100%" }}
            />
          )}
        )}
        <Input
          fullWidth
          onInputChange={this.props.onInputChange}
          inputComponent={SelectWrapped}
          value={this.props.tags ? "" : this.state.single}
          onChange={this.handleChange("single")}
          placeholder={this.props.placeholder || "Search"}
          id={"react-select-single" + this.props.id}
          inputRef={this.inputRef}
          inputProps={{
            classes,
            name: "react-select-single" + this.props.id,
            instanceId: "react-select-single" + this.props.id,
            simpleValue: true,
            options: this.state.items,
            onInputChange: (currentValue) => {
              this.handleInputChange(currentValue);
            },
            onCreateNewItem: this.newTagCreation,
            disabled: this.props.disabled,
            textLength: this.props.textLength,
            multi: this.props.multi,
            clearable: this.props.clearable,
          }}
          error={!!this.props.error}
        />
        {!!this.props.error && (
          <Typography color="error" style={{ fontSize: 12 }}>
            {this.props.error}
          </Typography>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(AutocompleteInput);
