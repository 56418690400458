import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from '@material-ui/icons/Clear';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link, useHistory } from "react-router-dom";
import "../Styles.css";
import * as routes from "../../constants/routes";
import SignOutPage from "../SignOut";
import AuthUserContext from "../../AuthUserContext";
import { InputAdornment } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../model/root.reducer";
import { filtersActions } from "../../model/actions/filters.actions";
import { debounce } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  search: {
    marginLeft: 30,
  },
  adornment: {
    opacity: 0.54,
    color: "rgb(158, 158, 158)",
  },
  inputRoot: {
    width: 300,
    borderRadius: 4,
    backgroundColor: theme.palette.common.white
  },
  searchIcon: {
    width: 36,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputInput: {
    padding: '6px 16px',
  },
}));

export const ButtonAppBar: React.FC<any> = () => {
  const classes = useStyles();
  const [searchText, setSearchText] = React.useState('')

  const searchedValue = useSelector( (state: RootState) => state.filters.search);

  useEffect(() => {
    searchedValue.length >= 1 && setSearchText(searchedValue) 
  }, [])

  const user = React.useContext(AuthUserContext);

  const balance = useSelector((state: RootState) => state.balance);

  const dispatch = useDispatch();

  const onLogoClick = () => {
    (window as any).location = '/';
  }

  if (!user) {
    return (
      <AppBar position="static">
        <Toolbar className="mainNav">
          <Typography className="logo" color="inherit" onClick={onLogoClick}>
            VaBank
          </Typography>
          <Button color="primary" className="button navLink">
            <Link to={routes.SIGN_IN}>Sign In</Link>
          </Button>
        </Toolbar>
      </AppBar>
    );
  }

  const getEndAdornmnet = () => {
    if (searchText) {
      return (
        <InputAdornment position="end" className={classes.adornment}>
          <IconButton onClick={handleClearClick}>
            <ClearIcon />
          </IconButton>
        </InputAdornment>
      );
    }
    
    return (
      <InputAdornment position="end" className={classes.adornment}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
      </InputAdornment>
    );
  };

  const handleFormSubmit = (event: any) => {
    event.preventDefault()
    onSearchChange(searchText)
  }

  const onSearchChange = debounce((value: string) => {
    dispatch(filtersActions.changeSearch(value))
  }, 1000)
  
  const handleSearchChange = (event: any) => {
    setSearchText(event.target.value)
  }
  
  const handleClearClick = () => {
    setSearchText('')
    onSearchChange('')
  }

  if (!balance) {
    return null;
  }

  return (
    <AppBar position="sticky">
      <Toolbar className="mainNav">
        <div className="SearchBar">
          <Typography className="logo" variant="h6" color="inherit" onClick={onLogoClick}>
            VaBank
          </Typography>
          <div className={classes.search}>
            <form onSubmit={handleFormSubmit}> 
              <InputBase
                value={searchText}
                onChange={handleSearchChange}
                placeholder="Search..."
                endAdornment={getEndAdornmnet()}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
                />
            </form>
          </div>
        </div>
        <Typography color="inherit" variant="body2" className="balance">
          {`Current: ${balance.totalBalance}`}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {`Should be: ${balance.estimatedBalance}`}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {`Difference: ${balance.difference}`}
        </Typography>
        <SignOutPage />
      </Toolbar>
    </AppBar>
  );
};

export default ButtonAppBar;
