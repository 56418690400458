
export default (data: any): {
	childrensIds: any,
	rootsIds: any
} => {
	let childrensIds: Record<string, any> = {}
	let rootsIds: string[] = []
	Object.values(data).forEach((e: any) => {
		if (e.depth === 0) {
			rootsIds.push(e.id)
			return;
		}
		if (!childrensIds[e.parentId]) {
			childrensIds[e.parentId] = []
		}
		childrensIds[e.parentId].push(e.id)
	});
	return { childrensIds, rootsIds }
}