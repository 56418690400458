import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { openDialog } from "../../../Acceptbox/Acceptbox";
import { useDispatch } from "react-redux";
import { moneyItemsActions } from "../../../../model/actions/moneyItems.actions";
import { MoneyItemEntity } from "../../../../model/reducers/moneyItems/MoneyItemEntity";
import { ReoccuringItemDialog } from "../../../ReoccuringItemDialog/ReoccuringItemDialog";

const useStyles = makeStyles((theme) => ({
  button: (theme as any).components.iconButton,
}));

const DeleteButton: React.FC<{ record: MoneyItemEntity } & any> = ({ record, disabled }: any) => {
  const classes: any = useStyles();

  const [isReccuringDialogOpen, setIsRecurringDialogOpen] = React.useState(false);
  
  const dispatch = useDispatch()

  const handleClick = () => {
    const isParent = record.isInheritedParent;
    const parentId = Boolean(record.inheritedParentId);
    if (isParent || parentId) {
      setIsRecurringDialogOpen(true);
    } else {
      openDialog(null, () => {
        dispatch(moneyItemsActions.deleteSingle({
          itemId: record.id, 
          isReoccuring: false
        }))
      });
    }
  };

  const onCancelChange = () => {
    setIsRecurringDialogOpen(false);
  };

  const onConfirmChange = (reccuring: boolean) => {
    if (reccuring) {
      dispatch(moneyItemsActions.deleteSingleReccuring({
        itemId: record.id, 
        isReoccuring: true
      }))
    } else {
      dispatch(moneyItemsActions.deleteSingle({
        itemId: record.id, 
        isReoccuring: false
      }))
    }
    setIsRecurringDialogOpen(false);
  };

  return (
      <IconButton
        className={classes.iconButton}
        aria-label="Delete"
        onClick={handleClick}
        disabled={disabled}
        edge="end"
      >
        {isReccuringDialogOpen && <ReoccuringItemDialog titleId={'Reocurring payment ' + record.id} onConfirm={onConfirmChange} onClose={onCancelChange} />}
        <DeleteIcon />
      </IconButton>
  );
};

export default DeleteButton;
