export const moneyFormat = (value, currency) => {
  value = typeof value === 'string' ? parseFloat(value) : value
  const isNegative = (value < 0) ? true : false;
  value = value.toFixed(2).split('.');
  value[0] = isNegative ? ['-', value[0].substring(1)] : ['', value[0]];
  value[0][1] = value[0][1].split(/(?=(?:...)*$)/).join(' ');
  value[0] = value[0].join('');
  value = value.join('.');
  return currency + ' ' + value;
}

export const moneyCategoriesBreadcrumbFormat = (namesArray) => {
  return namesArray.join(' > ')
}
