import Dialog from "@material-ui/core/Dialog";
import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  FormHelperText,
  Input,
  InputLabel,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { payeesActions } from "../../model/actions/payees.actions";
import Rest from "../../service/Rest";
import Endpoints from "../../constants/Endpoints";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { transformToApiRequestBody } from "../../common/transformToApiRequestBody";
import { createErrorHandler } from "../../common/createErrorHandler";
import {
  denormalizeEntityResponse,
} from "../../common/denormalizeResponse";

export type CreatePayeeInputs = {
  name: string;
  street?: string;
  zip?: string;
  city?: string;
  country?: string;
  taxid?: string;
  googleDriveFolderId?: string;
  invoiceoceanClientId?: string;
  infaktClientId?: string;
  defaultCurrency: string;
  internal: boolean;
};

export type Props = {
  onClose: () => void;
  onSuccess: (id: string) => void;
  name?: string;
};

const validationSchema = yup.object().shape({
  name: yup.string().min(6).required(),
  street: yup.string(),
  zip: yup.string(),
  city: yup.string(),
  country: yup.string(),
  taxid: yup.string(),
  googleDriveFolder: yup.string(),
  invoiceoceanClient: yup.string(),
  infaktClient: yup.string(),
  internal: yup.boolean(),
});

export const CreatePayee: React.FC<Props> = ({
  name = "",
  onClose,
  onSuccess,
}) => {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<CreatePayeeInputs>({
    resolver: yupResolver(validationSchema),
  });
  const dispatch = useDispatch();

  const handleError = createErrorHandler<CreatePayeeInputs>(setError, dispatch);

  const onSubmit: SubmitHandler<CreatePayeeInputs> = (formInput) => {
    Rest({
      url: Endpoints.payees,
      method: "POST",
      data: JSON.stringify({
        data: {
          attributes: transformToApiRequestBody(formInput),
          type: "payees",
        },
      }),
    })
      .then((result) => {
        dispatch(
          payeesActions.createPayee(denormalizeEntityResponse(result) as any)
        );

        onSuccess(result.data.id);
      })
      .catch((exception: any) => {
        handleError(exception);
      });
  };

  return (
    <Dialog maxWidth="md" fullWidth open={true} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Create payee</DialogTitle>
        <DialogContent>
          <Box pt={2} pb={2}>
            {" "}
            <FormGroup>
              <InputLabel>Name*</InputLabel>
              <FormControl>
                <Controller
                  name="name"
                  control={control}
                  defaultValue={name}
                  render={({ field }) => (
                    <Input
                      type="text"
                      {...field}
                      error={Boolean(errors.name)}
                    />
                  )}
                />
              </FormControl>
              <FormHelperText error>
                {errors.name && errors.name.message}
              </FormHelperText>
            </FormGroup>
          </Box>
          <Box pt={2} pb={2}>
            <FormGroup>
              <InputLabel>Street</InputLabel>
              <FormControl>
                <Controller
                  name="street"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <Input
                      type="text"
                      {...field}
                      error={Boolean(errors.street)}
                    />
                  )}
                />
              </FormControl>
              <FormHelperText error>
                {errors.street && errors.street.message}
              </FormHelperText>
            </FormGroup>
          </Box>
          <Box pt={2} pb={2}>
            <FormGroup>
              <InputLabel>Zip</InputLabel>
              <FormControl>
                <Controller
                  name="zip"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <Input type="text" {...field} error={Boolean(errors.zip)} />
                  )}
                />
              </FormControl>
              <FormHelperText error>
                {errors.zip && errors.zip.message}
              </FormHelperText>
            </FormGroup>
          </Box>
          <Box pt={2} pb={2}>
            <FormGroup>
              <InputLabel>City</InputLabel>
              <FormControl>
                <Controller
                  name="city"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <Input
                      type="text"
                      {...field}
                      error={Boolean(errors.city)}
                    />
                  )}
                />
              </FormControl>
              <FormHelperText error>
                {errors.city && errors.city.message}
              </FormHelperText>
            </FormGroup>
          </Box>
          <Box pt={2} pb={2}>
            <FormGroup>
              <InputLabel>Country</InputLabel>
              <FormControl>
                <Controller
                  name="country"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <Input
                      type="text"
                      {...field}
                      error={Boolean(errors.country)}
                    />
                  )}
                />
              </FormControl>
              <FormHelperText error>
                {errors.country && errors.country.message}
              </FormHelperText>
            </FormGroup>
          </Box>
          <Box pt={2} pb={2}>
            <FormGroup>
              <InputLabel>TaxID</InputLabel>
              <FormControl>
                <Controller
                  name="taxid"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <Input
                      type="text"
                      {...field}
                      error={Boolean(errors.taxid)}
                    />
                  )}
                />
              </FormControl>
              <FormHelperText error>
                {errors.taxid && errors.taxid.message}
              </FormHelperText>
            </FormGroup>
          </Box>
          <Box pt={2} pb={2}>
            <FormGroup>
              <InputLabel>Google drive folder</InputLabel>
              <FormControl>
                <Controller
                  name="googleDriveFolderId"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <Input
                      type="text"
                      {...field}
                      error={Boolean(errors.googleDriveFolderId)}
                    />
                  )}
                />
              </FormControl>
              <FormHelperText error>
                {errors.googleDriveFolderId &&
                  errors.googleDriveFolderId.message}
              </FormHelperText>
            </FormGroup>
          </Box>
          <Box pt={2} pb={2}>
            <FormGroup>
              <InputLabel>Invoiceocean client</InputLabel>
              <FormControl>
                <Controller
                  name="invoiceoceanClientId"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <Input
                      type="text"
                      {...field}
                      error={Boolean(errors.invoiceoceanClientId)}
                    />
                  )}
                />
              </FormControl>
              <FormHelperText error>
                {errors.invoiceoceanClientId &&
                  errors.invoiceoceanClientId.message}
              </FormHelperText>
            </FormGroup>
          </Box>
          <Box pt={2} pb={2}>
            <FormGroup>
              <InputLabel>Infakt client</InputLabel>
              <FormControl>
                <Controller
                  name="infaktClientId"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <Input
                      type="text"
                      {...field}
                      error={Boolean(errors.infaktClientId)}
                    />
                  )}
                />
              </FormControl>
              <FormHelperText error>
                {errors.infaktClientId && errors.infaktClientId.message}
              </FormHelperText>
            </FormGroup>
          </Box>
          <Box pt={2} pb={2}>
            <FormGroup>
              <InputLabel>Default currency</InputLabel>
              <FormControl>
                <Controller
                  name="defaultCurrency"
                  control={control}
                  defaultValue={"PLN"}
                  render={({ field }) => <Input type="text" {...field} />}
                />
              </FormControl>
              <FormHelperText error>
                {errors.defaultCurrency && errors.defaultCurrency.message}
              </FormHelperText>
            </FormGroup>
          </Box>
          <Box pt={2} pb={2}>
            <FormGroup>
              <InputLabel>Internal</InputLabel>
              <FormControl>
                <Controller
                  name="internal"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <Checkbox
                      checked={Boolean(field.value)}
                      onChange={(e) => field.onChange(e.target.checked)}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  )}
                />
              </FormControl>
            </FormGroup>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit">Save payee</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
