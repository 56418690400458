export const style = (theme: any) => ({
  fullGridItem: {
    flexGrow: 1,
  },
  margin: {
    marginLeft: theme.spacing(),
  },
  numberInput: {
    width: 90,
  },
  blueVariant: {
    backgroundColor: "lightskyblue",
  },
  lightYellowVariant: {
    backgroundColor: "lightyellow",
  },
  yellowVariant: {
    backgroundColor: "yellow",
  },
  redVariant: {
    backgroundColor: "red",
  },
  noneBackgroundVariant: {},
  orangeVariant: {
    backgroundColor: "orange",
  },
  "@global": {
    ".MuiInput-input-84": {
      fontSize: 13,
      height: 19,
    },
    ".Select-value": {
      fontSize: 13,
    },
    ".MuiSvgIcon-root-98": {
      fontSize: 20,
    },
  },
  autoComplete: {
    width: 150,
  },
  moneyCategory: {
    width: 120,
    lineHeight: 2,
    cursor: "pointer",
  },
  moneyCategoryUndefined: {
    color: "red",
  },
  disabled: {
    pointerEvents: "none",
  },
  iconBox: {
    ...theme.components.iconButton,
    display: "flex",
    "justify-content": "center",
    "align-items": "center",
  },
  icon: {
    fill: theme.palette.action.active,
  },
});
