import { createReducer } from "typesafe-actions";
import { PayeeApiEntity } from "./PayeeApiEntity";
import { payeesActions } from "../../actions/payees.actions";
import { denormalizeCollectionResponse } from "../../../common/denormalizeResponse";
import { WithId } from "../../../common/withId";
import { projectsActions } from "../../actions/projects.actions";
import { appActions } from "../../actions/app.actions";

export type PayeesState = {
    [key: string]: WithId<PayeeApiEntity>;
};

export const payeesReducer = createReducer<PayeesState>(
    {}
).handleAction(
    appActions.loaded,
    (
        state: PayeesState,
        action: ReturnType<typeof appActions.loaded>
    ) => {
        const result = denormalizeCollectionResponse(action.payload.payees);

        return result
    }
).handleAction(
    payeesActions.updateResults,
    (
        state: PayeesState,
        action: ReturnType<typeof payeesActions.updateResults>
    ) => {
        const result = denormalizeCollectionResponse(action.payload);

        return result
    }
).handleAction(
    payeesActions.createPayee,
    (
        state: PayeesState,
        action: ReturnType<typeof payeesActions.createPayee>
    ) => {
        return {
            ...state,
            [action.payload.id]: {
                ...action.payload,
                projectIds: []
            }
        }
    }
).handleAction(
    projectsActions.createProject,
    (
        state: PayeesState,
        action: ReturnType<typeof projectsActions.createProject>
    ) => {
        const payeeId = ((action.payload) as any).payeeId;
        const payee = state[payeeId];

        return {
            ...state,
            [payeeId]: {
                ...payee,
                projectIds: payee.projectIds.concat(parseInt(action.payload.id, 10))
            }
        }
    }
)
