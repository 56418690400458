import { AxiosResponse } from "axios";
import { camelCase } from "lodash";
import { toastsActions } from "../toasts/toasts.actions";
import { ApiError } from "./apiError";

export const createErrorHandler =
  <T>(setError: Function, dispatch: Function) =>
  (exception: Error & { response: AxiosResponse }) => {
    if (!exception.response) {
      dispatch(toastsActions.show("Unexpected error", "error"));

      return;
    }

    const { response } = exception!;
    const { errors } = response!.data;

    errors.forEach((error: ApiError) => {
      dispatch(toastsActions.show("Validaion error - check your form inputs", "error"))
      const field = camelCase(error.detail.split(" - ")[0]) as keyof T;

      setError(
        field,
        {
          type: error.title,
          message: error.detail,
        },
        {
          shouldFocus: true,
        }
      );
    });
  };
