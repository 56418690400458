import { createAction } from "typesafe-actions"
import { JsonApiResponse } from "../../common/JsonApiResponse"
import { WithId } from "../../common/withId"
import { ProjectApiEntity } from "../reducers/projects/ProjectApiEntity"

const loadSuccess = createAction('projects/loadSuccess')<JsonApiResponse<ProjectApiEntity>>()
const createProject = createAction('projects/createProject')<WithId<Partial<ProjectApiEntity>>>()

export const projectsActions = {
    loadSuccess,
    createProject
}
