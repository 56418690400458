import { createAction } from "typesafe-actions"
import { JsonApiResponse } from "../../common/JsonApiResponse"
import { WithId } from "../../common/withId"
import { MoneyItemApiEntity } from "../reducers/moneyItems/MoneyItemApiEntity"

const loadSuccess = createAction('moneyItems/loadSuccess')<JsonApiResponse<MoneyItemApiEntity>>()

const updateSingle = createAction('moneyItems/updateSingle')<Partial<MoneyItemApiEntity>>()
const updateSingleSuccessful = createAction('moneyItems/updateSingleSuccessful')<WithId<Partial<MoneyItemApiEntity>>>()

const updateSingleReccuring = createAction('moneyItems/updateSingleReccuring')<Partial<MoneyItemApiEntity>>();

const addNewPayment = createAction('moneyItems/addNewPayment')<Partial<MoneyItemApiEntity>>()

const deleteSingle = createAction('moneyItems/deleteSingle')<{ itemId: string, isReoccuring: boolean }>()
const deleteSingleReccuring = createAction('moneyItems/deleteSingleReccuring')<{ itemId: string, isReoccuring: boolean }>()

const reorder = createAction('moneyItems/reorder')<{ activeId: string, overId: string}>()

const reload = createAction('moneyItems/reload')<void>()

export const moneyItemsActions = {
    loadSuccess,
    updateSingle,
    updateSingleSuccessful,
    updateSingleReccuring,
    deleteSingleReccuring,
    deleteSingle,
    reorder,
    reload,
    addNewPayment
}
