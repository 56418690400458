import React from "react";
import { useSelector } from "react-redux";
import { ToastItem } from "./ToastItem";
import { Toast } from "./toasts.reducer";
import { RootState } from "../model/root.reducer";
import Snackbar from "@material-ui/core/Snackbar";

export const Toasts: React.FC = React.memo(() => {
  const toasts = useSelector((state: RootState) => state.toasts.items);

  return (
    <Snackbar
      ContentProps={{
        style: {
          opacity: 1,
          transform: "none",
        },
      }}
      TransitionProps={{
        appear: false,
      }}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={toasts.length > 0}
    >
      <div>
        {toasts.map((item: Toast) => (
          <ToastItem key={item.id} {...item} />
        ))}
      </div>
    </Snackbar>
  );
});
