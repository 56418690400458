import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import CashItem from '../CashItems/CashItem/CashItem';

export const SortableItem: React.FC<any> = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.id});
  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  
  return (
    <div ref={setNodeRef} style={style as any} {...attributes}>
      <CashItem {...props} listeners={listeners} />
    </div>
  );
};
