import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 115,
    marginRight: 4,
  },
}));

export const DateInput: React.FC<any> = React.memo(
  ({ value: valueProp, disabled, error, errorMessage, onBlur }) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(valueProp);

    useEffect(() => {
      setValue(valueProp);
    }, [valueProp]);

    React.useEffect(() => {
      setValue(valueProp);
    }, [valueProp]);

    const handleBlur = () => {
      onBlur(value);
    };

    const handleKeyPress = (event: {
      which: number;
      preventDefault: () => void;
    }) => {
      if (event.which === 9) {
        event.preventDefault();
      }
    };

    const handleChange = (event: { target: { value: any } }) => {
      setValue(event.target.value);
    };

    return (
      <TextField
        className={classes.root}
        onKeyUp={handleKeyPress}
        type="date"
        onBlur={handleBlur}
        value={moment(value).format("YYYY-MM-DD")}
        onChange={handleChange}
        disabled={disabled}
        error={error}
        helperText={error ? errorMessage : ""}
      />
    );
  }
);

export default DateInput;
