import { createReducer } from "typesafe-actions";
import { denormalizeCollectionResponse } from "../../../common/denormalizeResponse";
import { WithId } from "../../../common/withId";
import { appActions } from "../../actions/app.actions";
import { DealApiEntity } from "./DealApiEntity";

export type DealsState = {
  [key: string]: WithId<DealApiEntity>;
};

export const dealsReducer = createReducer<DealsState>({}).handleAction(
  appActions.loaded,
  (state: DealsState, action: ReturnType<typeof appActions.loaded>) => {
    const result = denormalizeCollectionResponse(action.payload.deals);

    return result;
  }
);
