import React from "react";
import Datefilter from "./Datefilter";
import { useDispatch, useSelector } from "react-redux";
import { filtersActions } from "../../model/actions/filters.actions";
import { RootState } from "../../model/root.reducer";
import { IconButton } from "material-ui";
import FilterListIcon from "@material-ui/icons/FilterList";
import { DetailFilters } from "./DetailFilters";
import moment, { Moment } from "moment";
import FileDownloadIcon from "@material-ui/icons/GetApp";
import {Menu, MenuItem} from "@material-ui/core";
import _ from "lodash";
import {FiltersState} from "../../model/reducers/filters/filters.reducer";
import MoneyItems from "../../service/MoneyItems";

const Filters: React.FC<{}> = () => {
  const [open, setOpen] = React.useState(false);
  const [exportOpen, setExportOpen] = React.useState(false);
  const [exportAnchor, setExportAnchor] = React.useState<null | HTMLElement>(null);
  const [currentDates, setCurrentDates] = React.useState<{
    startDate: Moment,
    endDate: Moment
  } | null>(null);
  const filters = useSelector((state: RootState) => { return state.filters });

  const buildRange = (from: string | null, to: string | null) => {
    let result = "";

    if (!from && !to) {
      return undefined;
    }

    if (from) {
      result += from;
    }

    result += "*";

    if (to) {
      result += to;
    }

    return result;
  }

  const buildReccuringFilter = (filters: FiltersState) => {
    const { recurringChildren, recurringOrphan, recurringParents } = filters;

    const recurringAgreggated = [
      recurringChildren && "children",
      recurringOrphan && "orphan",
      recurringParents && "children",
    ].filter((value) => !!value).join(",");

    return !!recurringAgreggated ? recurringAgreggated : undefined
  }

  const getFilters = () => {

    const search = filters.search;

    const startDate = moment(filters.startOfMonth).format("YYYY-MM-DD");
    const endDate = moment(filters.endOfMonth).format("YYYY-MM-DD");
    if (startDate === "Invalid date" || endDate === "Invalid date") return;

    const payeeId = filters.payeeId;
    const projectId = filters.projectId;
    const moneyCategories = filters.moneyCategories;

    let payload = _.omitBy(
      {
        search,
        range: `${startDate}*${endDate}`,
        "payee-id": payeeId,
        "project-id": projectId,
        money_category_id: moneyCategories ? moneyCategories : undefined,
        amount: buildRange(filters.amountFrom, filters.amountTo),
        "money-account": filters.moneyAccountId || undefined,
        recurring: buildReccuringFilter(filters),
      },
      _.isUndefined
    );

    if (!payload.money_category_id) {
      delete payload.money_category_id;
    }

    return payload;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickExportOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExportAnchor(event.currentTarget);
    setExportOpen(true);
  }

  const handleExportClose = () => {
    setExportOpen(false);
    setExportAnchor(null);
  }

  const handleExportDownload = (response: any) => {
    if(response !== undefined) {
      const blobURL = window.URL.createObjectURL(new Blob([response['data']], { type: response['type'] }));
      const link = document.createElement('a');
      link.setAttribute('style', 'display: none');
      document.body.appendChild(link);
      link.setAttribute('href', blobURL);
      link.setAttribute('download', response['filename'])
      link.click()
      window.URL.revokeObjectURL(blobURL);
    }
  }

  const handleClickCsvExport = () => {
    const filters = getFilters();
    MoneyItems.getExport('csv', filters).then((response) => {
      handleExportDownload(response);
      handleExportClose();
    });
  }

  const handleClickXlsxExport = () => {
    const filters = getFilters();
    MoneyItems.getExport('excel', filters).then((response) => {
      handleExportDownload(response);
      handleExportClose();
    });
  }

  const dispatch = useDispatch();

  const handleDateTrigger = (dates: any) => {
    if (dates) {
      dispatch(filtersActions.changeDateRange(dates));
    }
  };

  const dates = useSelector((state: RootState) => {
    return [state.filters.startOfMonth, state.filters.endOfMonth];
  });

  return (
    <>
      <div>
        <Datefilter
          className="center"
          startOfMonth={dates[0]}
          endOfMonth={dates[1]}
          handleDateTrigger={handleDateTrigger}
        />
      </div>
      <div>
        <IconButton onClick={handleClickOpen}>
          <FilterListIcon />
        </IconButton>
        <IconButton id="export-button"
                    onClick={handleClickExportOpen}
                    aria-controls={exportOpen ? 'export-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={exportOpen ? 'true' : undefined}>
          <FileDownloadIcon />
        </IconButton>
        <Menu id="export-menu" open={exportOpen} anchorEl={exportAnchor} onClose={handleExportClose} MenuListProps={{'aria-labelledby': 'export-button'}}>
          <MenuItem onClick={handleClickCsvExport}>CSV</MenuItem>
          <MenuItem onClick={handleClickXlsxExport}>XLSX</MenuItem>
        </Menu>
        {open && <DetailFilters onClose={() => setOpen(false)} />}
      </div>
    </>
  );
};

export default Filters;
