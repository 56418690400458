import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { toastsActions, ToastType } from "./toasts.actions";
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

type Props = {
  message: string;
  type: ToastType;
  isHiding: boolean;
  id: string;
}



export const ToastItem: React.FC<Props> = ({
  message,
  isHiding,
  id,
  type
}) => {
  const dispatch = useDispatch();

  const onCloseClick = useCallback(() => {
    dispatch(toastsActions.hide(id));
  }, [dispatch]);

  return (
    <Alert severity={type as any} onClose={onCloseClick} className={isHiding ? 'fade-out' : ''}>
      <AlertTitle>
        {type}
      </AlertTitle>
        <span dangerouslySetInnerHTML={{
          __html: message
        }} />
    </Alert>
  );
};
