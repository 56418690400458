import { createReducer } from "typesafe-actions";
import { denormalizeCollectionResponse } from "../../../common/denormalizeResponse";
import { WithId } from "../../../common/withId";
import { appActions } from "../../actions/app.actions";
import { TagsApiEntity } from "./TagsApiEntity";

export type TagsState = {
  [key: string]: WithId<TagsApiEntity>;
};

export const tagsReducer = createReducer<TagsState>({}).handleAction(
  appActions.loaded,
  (
    state: TagsState,
    action: ReturnType<typeof appActions.loaded>
  ) => {
    const result = denormalizeCollectionResponse(action.payload.tags);

    return result;
  }
);
