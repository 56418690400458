import { Checkbox, DialogActions, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import Rest from "../../service/Rest";
import Endpoints from "../../constants/Endpoints";
import AutoCompleteInputWrapper from "../AutocompleteInput/AutoCompleteInputWrapper";
import { selectPayees } from "../../model/selectors/payees.selector";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { transformToApiRequestBody } from "../../common/transformToApiRequestBody";
import { projectsActions } from "../../model/actions/projects.actions";
import { createErrorHandler } from "../../common/createErrorHandler";
import { RootState } from "../../model/root.reducer";
import { ProjectApiEntity } from "../../model/reducers/projects/ProjectApiEntity";
import { orderBy, values } from "lodash";
import { toastsActions } from "../../toasts/toasts.actions";
import "react-select/dist/react-select.css";
import {
  denormalizeCollectionResponse,
  denormalizeEntityResponse,
} from "../../common/denormalizeResponse";

export type CreateProjectInputs = {
  name: string;
  description: string;
  payeeId: string;
  manager: string;
  status: "archived" | "active" | "suspended";
  billingModel: "time_and_material" | "body_leasing" | "fixed_price";
  hubstaffProjectId: string;
  trelloBoardId: string;
  gitlabProjectId: string;
  sentryProjectId: string;
  googleDriveFolderId: string;
  projectGroupIds: string[];
  agileMethodology: boolean;
  dealId: string;
} & Partial<ProjectApiEntity>;

export type Props = {
  onSuccess: (id: string) => void;
  onClose: () => void;
  payeeId?: string;
  name?: string;
};

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .min(6)
    .max(24)
    .matches(/^[a-z](\d|\w)+[a-z]$/, "Does not match required format")
    .required(),
  manager: yup.string(),
  description: yup.string().min(6).required(),
  payeeId: yup.string().required(),
  status: yup.string().oneOf(["archived", "active", "suspended"]),
  billingModel: yup
    .string()
    .oneOf(["time_and_material", "body_leasing", "fixed_price"]),
  hubstaffProjectId: yup.string(),
  trelloBoardId: yup.string(),
  gitlabProjectId: yup.string(),
  sentryProjectId: yup.string(),
  googleDriveFolderId: yup.string(),
  projectGroupIds: yup.array(),
  dealId: yup.string(),
  agileMethodology: yup.boolean(),
});

export const CreateProject: React.FC<Props> = ({
  payeeId = "",
  name = "",
  onClose,
  onSuccess,
}) => {
  const {
    control,
    handleSubmit,
    setError,
    watch,
    register,
    formState: { errors },
  } = useForm<CreateProjectInputs>({
    resolver: yupResolver(validationSchema),
  });
  const dispatch = useDispatch();

  const payees = useSelector(selectPayees);
  const people = useSelector((state: RootState) =>
    orderBy(Object.values(state.people), ["firstName", "lastName"])
  );
  const deals = useSelector((state: RootState) =>
    Object.values(state.deals).map((item) => ({
      name: item.title,
      id: item.id,
    }))
  );

  const projectGroups = useSelector((state: RootState) =>
    orderBy(Object.values(state.projectGroups), "name").map((item) => ({
      value: item.id,
      label: item.name,
    }))
  );

  const handleError = createErrorHandler<CreateProjectInputs>(
    setError,
    dispatch
  );

  const onSubmit: SubmitHandler<CreateProjectInputs> = (formInput) => {
    Rest({
      url: Endpoints.projectsV2,
      method: "POST",
      data: JSON.stringify({
        data: {
          attributes: transformToApiRequestBody({
            ...formInput,
            projectGroupIds: formInput.projectGroupIds.join(","),
          }),
          type: "projects",
        },
      }),
    })
      .then((result) => {
        dispatch(
          projectsActions.createProject(
            denormalizeEntityResponse(result) as any
          )
        );

        dispatch(
          toastsActions.show(
            `Project created with id ${result.data.id}`,
            "success"
          )
        );

        onSuccess(result.data.id);
      })
      .catch((exception: any) => {
        handleError(exception);
      });
  };

  return (
    <Dialog maxWidth="md" fullWidth open={true} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>New project</DialogTitle>
        <DialogContent>
          <Box pt={2} pb={2}>
            <FormGroup>
              <InputLabel>Name*</InputLabel>
              <FormControl>
                <Controller
                  name="name"
                  control={control}
                  defaultValue={name}
                  render={({ field }) => <TextField type="text" {...field} />}
                />
              </FormControl>
              <FormHelperText error>
                {!!errors.name && errors.name.message}
              </FormHelperText>
            </FormGroup>
          </Box>
          <Box pt={2} pb={2}>
            <FormGroup>
              <InputLabel>Description*</InputLabel>
              <FormControl>
                <Controller
                  name="description"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <TextField fullWidth type="text" {...field} />
                  )}
                />
              </FormControl>
              <FormHelperText error>
                {!!errors.description && errors.description.message}
              </FormHelperText>
            </FormGroup>
          </Box>
          <Box pt={2} pb={2}>
            <FormGroup>
              <InputLabel>Payee*</InputLabel>
              <FormControl>
                <Controller
                  name="payeeId"
                  control={control}
                  defaultValue={payeeId}
                  render={({ field }) => (
                    <AutoCompleteInputWrapper
                      {...field}
                      selectItems={payees}
                      onSelectLabel={field.onChange}
                      selectId="payeeId"
                    />
                  )}
                />
              </FormControl>
              <FormHelperText error>
                {!!errors.payeeId && errors.payeeId.message}
              </FormHelperText>
            </FormGroup>
          </Box>
          <Box pt={2} pb={2}>
            <FormGroup>
              <InputLabel>Status*</InputLabel>
              <FormControl>
                <Controller
                  name="status"
                  control={control}
                  defaultValue={"active"}
                  render={({ field }) => (
                    <Select native {...field}>
                      <option value={"active"}>Active</option>
                      <option value={"suspended"}>Suspended</option>
                      <option value={"archived"}>Archived</option>
                    </Select>
                  )}
                />
              </FormControl>
              <FormHelperText error>
                {!!errors.status && errors.status.message}
              </FormHelperText>
            </FormGroup>
          </Box>
          <Box pt={2} pb={2}>
            <FormGroup>
              <InputLabel>Billing model*</InputLabel>
              <FormControl>
                <Controller
                  name="billingModel"
                  control={control}
                  defaultValue={"time_and_material"}
                  render={({ field }) => (
                    <Select native {...field}>
                      <option value="time_and_material">
                        Time and material
                      </option>
                      <option value={"body_leasing"}>Body leasing</option>
                      <option value={"fixed_price"}>Fixed price</option>
                    </Select>
                  )}
                />
              </FormControl>
              <FormHelperText error>
                {!!errors.billingModel && errors.billingModel.message}
              </FormHelperText>
            </FormGroup>
          </Box>
          <Box pt={2} pb={2}>
            <FormGroup>
              <InputLabel>Deal</InputLabel>
              <FormControl>
                <Controller
                  name="dealId"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <AutoCompleteInputWrapper
                      {...field}
                      selectItems={deals}
                      onSelectLabel={field.onChange}
                      selectId="dealId"
                    />
                  )}
                />
              </FormControl>
              <FormHelperText error>
                {!!errors.dealId && errors.dealId.message}
              </FormHelperText>
            </FormGroup>
          </Box>
          <Box pt={2} pb={2}>
            <FormGroup>
              <InputLabel>Manager</InputLabel>
              <FormControl>
                <Controller
                  name="manager"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <AutoCompleteInputWrapper
                      {...field}
                      selectItems={people.map((person) => ({
                        name: `${person.firstName} ${person.lastName}`,
                        id: person.id,
                      }))}
                      onSelectLabel={field.onChange}
                      selectId="manager"
                    />
                  )}
                />
              </FormControl>
              <FormHelperText error>
                {!!errors.manager && errors.manager.message}
              </FormHelperText>
            </FormGroup>
          </Box>
          <Box pt={2} pb={2}>
            <FormGroup>
              <InputLabel>Gitlab project ID</InputLabel>
              <FormControl>
                <Controller
                  name="gitlabProjectId"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <TextField fullWidth type="text" {...field} />
                  )}
                />
              </FormControl>
              <FormHelperText error>
                {!!errors.gitlabProjectId && errors.gitlabProjectId.message}
              </FormHelperText>
            </FormGroup>
          </Box>
          <Box pt={2} pb={2}>
            <FormGroup>
              <InputLabel>Sentry project ID</InputLabel>
              <FormControl>
                <Controller
                  name="sentryProjectId"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <TextField fullWidth type="text" {...field} />
                  )}
                />
              </FormControl>
              <FormHelperText error>
                {!!errors.sentryProjectId && errors.sentryProjectId.message}
              </FormHelperText>
            </FormGroup>
          </Box>
          <Box pt={2} pb={2}>
            <FormGroup>
              <InputLabel>Google Drive folder id</InputLabel>
              <FormControl>
                <Controller
                  name="googleDriveFolderId"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <TextField fullWidth type="text" {...field} />
                  )}
                />
              </FormControl>
              <FormHelperText error>
                {!!errors.googleDriveFolderId &&
                  errors.googleDriveFolderId.message}
              </FormHelperText>
            </FormGroup>
          </Box>
          <Box pt={2} pb={2}>
            <FormGroup>
              <InputLabel>Trello Board Id</InputLabel>
              <FormControl>
                <Controller
                  name="trelloBoardId"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <TextField fullWidth type="text" {...field} />
                  )}
                />
              </FormControl>
              <FormHelperText error>
                {!!errors.trelloBoardId && errors.trelloBoardId.message}
              </FormHelperText>
            </FormGroup>
          </Box>
          <Box pt={2} pb={2}>
            <FormGroup>
              <InputLabel>Hubbstaff project id</InputLabel>
              <FormControl>
                <Controller
                  name="hubstaffProjectId"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <TextField fullWidth type="text" {...field} />
                  )}
                />
              </FormControl>
              <FormHelperText error>
                {!!errors.hubstaffProjectId && errors.hubstaffProjectId.message}
              </FormHelperText>
            </FormGroup>
          </Box>
          <Box pt={2} pb={2}>
            <FormGroup>
              <InputLabel>Project Groups</InputLabel>
              <FormControl>
                <Controller
                  name="projectGroupIds"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <ReactSelect
                      defaultValue={[]}
                      name="projectGroupIds"
                      multi
                      options={projectGroups}
                      inputRef={field.ref}
                      classNamePrefix="addl-class"
                      value={projectGroups.filter(
                        (c) => field.value.indexOf(c.value) !== -1
                      )}
                      onChange={
                        ((val: any[]) => {
                          return val
                            ? field.onChange(val.map((item) => item.value))
                            : [];
                        }) as any
                      }
                    />
                  )}
                />
              </FormControl>
              <FormHelperText error>
                {!!errors.projectGroupIds &&
                  (errors as any).projectGroupIds.message}
              </FormHelperText>
            </FormGroup>
          </Box>
          <Box pt={2} pb={2}>
            <FormGroup>
              <InputLabel>Agile Methodology</InputLabel>
              <FormControl>
                <Controller
                  name="agileMethodology"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <Checkbox
                      checked={Boolean(field.value)}
                      onChange={(e) => field.onChange(e.target.checked)}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  )}
                />
              </FormControl>
            </FormGroup>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save project
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
