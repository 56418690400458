import React, { useMemo } from "react";
import TreeSelect from "./TreeSelect";
import without from "lodash/without";

export const MoneyCategorySelect: React.FC<any> = ({
  classes,
  value,
  onChange,
  elements = [],
  filter,
  label = "Money categories",
}) => {
  const mapElements = useMemo(
    () =>
      elements.map((x: any) => {
        const { breadcrumb, name, ...rest } = x;

        const breadcrumbWithoutRoot = breadcrumb
          ? without(breadcrumb.split(" > "), "Root")
          : [];

        return {
          id: x.id,
          name,
          breadcrumb: breadcrumbWithoutRoot.length
            ? breadcrumbWithoutRoot.join(" > ")
            : name,
          ...rest,
        };
      }),
    [elements]
  );

  return (
    <TreeSelect
      choices={mapElements}
      input={{
        onChange,
        value,
      }}
      label={label}
      optionText="name"
      tagLabelKey="breadcrumb"
      filter={filter}
    />
  );
};

export default MoneyCategorySelect;
