import React from "react";
import { useHistory } from "react-router-dom";
import { CreatePayee } from "./CreatePayee";

export type Props = {
};

export const CreatePayeeContainer: React.FC<Props> = () => {
    const history = useHistory();

    const onClose = () => {
        history.push('/home');
    }

  return (
    <CreatePayee onClose={onClose} onSuccess={onClose} />
  );
};
