import React, {  } from 'react';
import {Bar} from 'react-chartjs-2';
import moment from 'moment';
import { moneyFormat } from './formats';

const RealisticChart = ({ currency, chartData }) => {
  const balancesArray = chartData.map(item => item.balance);
  const incomesArray = chartData.map(item => item.income);
  const outcomesArray = chartData.map(item => item.outcome);
  const labelsArray = chartData.map(item => moment(item.date).format('DD/MM/YYYY'));

  const options = {
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => moneyFormat(tooltipItem.yLabel, currency),
      }
    },
    responsive: true,
    scales: {
      xAxes: [{
        stacked: true,
      }],
      yAxes: [{
        ticks: {
          userCallback: (value) => moneyFormat(value, currency),
        },
        stacked: true
      }]
    },
    tooltipTemplate: function (valueObject) {
      return valueObject.label + ': $' + valueObject.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }

  const data = {
    labels: labelsArray,
    datasets: [
      {
        label: 'Balance',
        type:'line',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'blue',
        borderColor: 'blue',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'blue',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'blue',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: balancesArray,
      },
      {
        label: 'Income',
        fill: false,
        type:'bar',
        lineTension: 0.1,
        backgroundColor: 'green',
        borderColor: 'green',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'green',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'green',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: incomesArray,
      },
      {
        label: 'Outcome',
        type:'bar',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'red',
        borderColor: 'red',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'red',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'red',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: outcomesArray,
      },
    ]
  };

  return (
    <div>
      <h2>Realistic</h2>
      <Bar data={data} options={options}/>
    </div>
  );
}

export default RealisticChart
