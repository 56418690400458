import React from 'react'
import { withStyles } from '@material-ui/core';
import DropdownTreeSelect from 'react-dropdown-tree-select'
import _ from 'lodash'
import getTree from './getTree';
import 'react-dropdown-tree-select/dist/styles.css'

const style = (theme: any) => ({
	root: {
		'& .dropdown .dropdown-content': {
			maxHeight: 200,
			width: 200,
			overflow: 'auto',
			zIndex: 2
		}
	}
})

const TreeSelect: React.FC<any> = (props) => {
	const {
		choices,
		input,
		classes,
		label,
		optionText,
		filter,
		tagLabelKey,
	} = props;

	const { childrensIds, rootsIds } = getTree(choices);
	const data = _.chain(choices).keyBy('id').value();

	const newInputValue = Boolean(input.value) ? input.value.toString().split(',') : ""

	const createElement = (id: string) => ({
		label: data[id][optionText],
		tagLabel: data[id][tagLabelKey],
		value: id,
		children: getChilds(id),
		disabled: filter ? false : data[id].hasChildren,
		checked: newInputValue.includes(id)
	})

	const getChilds = (root: string) => {
		if (!childrensIds[root]) return;
		return childrensIds[root].map((id: any) => createElement(id))
	}

	const tree = rootsIds.map((id: string) => createElement(id))

	const onChange = (_currentNode: any, selectedNodes: any) => {
		input.onChange(selectedNodes)
	}

	return (
		<DropdownTreeSelect
			keepTreeOnSearch
			texts={{ placeholder: label }}
			className={classes.root}
			mode={filter?'multiSelect':'radioSelect'}
			showPartiallySelected={!!filter}
			// name={input.name}
			data={tree}
			onChange={onChange}
		/>
	)
}

export default withStyles(style)(TreeSelect);