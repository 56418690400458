import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      transform: "translateZ(0px)",
      flexGrow: 1,
    },
    exampleWrapper: {
      position: "relative",
      marginTop: theme.spacing(3),
      height: 380,
    },
    radioGroup: {
      margin: theme.spacing(1, 0),
    },
    speedDial: {
      position: "absolute",
      "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
        bottom: theme.spacing(2),
        right: theme.spacing(2),
      },
      "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
        top: theme.spacing(2),
        left: theme.spacing(2),
      },
    },
  })
);

const actions = [
  {
    icon: <FormatListBulletedIcon />,
    name: "New item",
    url: "/home/money_items/new",
  },
  { icon: <GroupAddIcon />, name: "New payee", url: "/home/payee/create" },
  {
    icon: <AddToPhotosIcon />,
    name: "New project",
    url: "/home/project/create",
  },
];

export const FabAddition: React.FC = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const history = useHistory();

  const onActionClick = (url: string) => {
    history.push(url);
  };

  return (
    <div className={classes.root}>
      <div className={classes.exampleWrapper}>
        <SpeedDial
          ariaLabel="Speed dial"
          className={classes.speedDial}
          hidden={true}
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={true}
          direction="left"
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={() => onActionClick(action.url)}
            />
          ))}
        </SpeedDial>
      </div>
    </div>
  );
};
