import axios from 'axios'
import Endpoints from '../constants/Endpoints'

const updateAuthToken = (token: string) => {
	const onlyToken = token.replace('Bearer ', '');
	localStorage.setItem('token', onlyToken);
}

const handleLoginResponse = (response: any) => {
	localStorage.setItem('user', JSON.stringify(response));
	// userStore.setData(response);
	window.location.reload();
}

const _loginPermisions = [
	'can_login',
	'can_see_money_items_menu'
]

const hasPermissions = (userData: any) => {
	if (!userData) return false;
	return _loginPermisions.every(x => {
		return userData.permissions.includes(x)
	});
}

const login = async (email: string, password: string) => {

	try {
		const { headers, data } = await axios.post(Endpoints.login, {
			person: {
				email, password
			}
		})
		if (!hasPermissions(data)) {
			throw new Error("Permission denied")
		}

		if (data.permissions.includes) {
			updateAuthToken(headers['authorization']);
			handleLoginResponse(data)
		}
	} catch (error) {
		const response = error.response;
		throw new Error(response.data.error)
	}
}

const logout = async () => {
	localStorage.removeItem('token');
	localStorage.removeItem('user');
	localStorage.removeItem('user-email');
}

export default {
	login,
	logout,
	hasPermissions,
}