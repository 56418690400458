import React, { useCallback, useState } from "react";
import { Grid, withStyles } from "@material-ui/core";
import DragButton from "./components/DragButton";
import LockButton from "./components/LockButton";
import SettingsButton from "./components/SettingsButton";
import DateInput from "./components/DateInput";
import DeleteButton from "./components/DeleteButton";
import HelpIcon from "./components/HelpIcon";
import moment from "moment";
import BalanceInput from "./components/BalanceInput";
import NameInput from "./components/NameInput";
import MoneyCategoryInput from "./components/MoneyCategoryInput";
import AutocompleteInput from "./components/AutocompleteInput";
import ParenthoodIcon from "./components/ParenthoodIcon";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isEqual } from "lodash";
import { selectProjects } from "../../../model/selectors/projects.selector";
import { selectPayees } from "../../../model/selectors/payees.selector";
import { selectMoneyCategories } from "../../../model/selectors/moneyCategories.selector";
import { useEffect } from "react";
import { moneyItemsActions } from "../../../model/actions/moneyItems.actions";
import { ReoccuringItemDialog } from "../../ReoccuringItemDialog/ReoccuringItemDialog";
import { ProjectApiEntity } from "../../../model/reducers/projects/ProjectApiEntity";
import { PayeeApiEntity } from "../../../model/reducers/payees/PayeeApiEntity";
import { useHistory } from "react-router-dom";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { MoneyItemApiEntity } from "../../../model/reducers/moneyItems/MoneyItemApiEntity";
import _ from "lodash";
import { objectDeepDifference } from "../../../common/objectDeepDifference";
import { RootState } from "../../../model/root.reducer";
import { CreatePayee } from "../../CreatePayee/CreatePayee";
import { CreateProject } from "../../CreateProject/CreateProject";
import { getColor } from "./getColor";
import { style } from "./style";
import { AddiotionalActions } from "./components/AdditionalActions";

const mapFormToEntityProps = (
  cashItemFormValues: Partial<CashItemFormValues>
): Partial<MoneyItemApiEntity> => {
  const mapped = {
    id: cashItemFormValues.id,
    transactionOn: cashItemFormValues.date,
    costOn: cashItemFormValues.date,
    incomeOn: cashItemFormValues.date,
    taxableOn: cashItemFormValues.taxableOn,
    vabankBalance: cashItemFormValues.vabankBalance,
    amountNetto: cashItemFormValues.amountNetto,
    amountVat: cashItemFormValues.amountVat,
    amountBrutto: cashItemFormValues.amountBrutto,
    amountNettoCalculation: cashItemFormValues.amountNettoCalculation,
    amountVatCalculation: cashItemFormValues.amountVatCalculation,
    amountBruttoCalculation: cashItemFormValues.amountBruttoCalculation,
    name: cashItemFormValues.name,
    payeeId:
      typeof cashItemFormValues.payeeId !== "undefined"
        ? cashItemFormValues.payeeId
          ? parseInt(cashItemFormValues.payeeId, 10)
          : null
        : undefined,
    projectId:
      typeof cashItemFormValues.projectId !== "undefined"
        ? cashItemFormValues.projectId
          ? parseInt(cashItemFormValues.projectId, 10)
          : null
        : undefined,
    moneyCategoryId:
      typeof cashItemFormValues.moneyCategoryId !== "undefined"
        ? cashItemFormValues.moneyCategoryId
          ? parseInt(cashItemFormValues.moneyCategoryId, 10)
          : null
        : undefined,
  };

  // pick only undefined values
  return _.omitBy(mapped, _.isUndefined);
};

export type CashItemFormValues = {
  paid: boolean;
  vabankBalance?: string;
  date?: string;
  moneyCategoryId: string;
  payeeId: string;
  projectId: string;
  name: string;
  amountNetto: string;
  amountVat: string;
  amountBrutto: string;
  id: string;
  amountNettoCalculation: string;
  amountVatCalculation: string;
  amountBruttoCalculation: string;
  isFromProps: boolean;
  taxableOn?: string;
};

const createVariant = (props: any): Record<string, any> => ({
  none: props.classes.noneBackgroundVariant,
  blue: props.classes.blueVariant,
  yellow: props.classes.yellowVariant,
  lightyellow: props.classes.lightYellowVariant,
  red: props.classes.redVariant,
  orange: props.classes.orangeVariant,
});

const CashItem: React.FC<
  any & {
    listeners: any;
  }
> = (props) => {
  const formStateFromProps: CashItemFormValues = {
    id: props.id,
    paid: props.paid,
    vabankBalance: props.vabankBalance,
    date: props.transactionOn,
    moneyCategoryId: props.moneyCategoryId,
    payeeId: props.payeeId,
    projectId: props.projectId,
    name: props.name,
    amountNetto: parseFloat(props.amountNetto).toFixed(2),
    amountVat: parseFloat(props.amountVat).toFixed(2),
    amountBrutto: parseFloat(props.amountBrutto).toFixed(2),
    amountNettoCalculation: props.amountNettoCalculation,
    amountVatCalculation: props.amountVatCalculation,
    amountBruttoCalculation: props.amountBruttoCalculation,
    taxableOn: props.taxableOn,
    isFromProps: true,
  };

  const [formState, setFormState] =
    useState<CashItemFormValues>(formStateFromProps);
  const [prevFormState, setPrevFormState] =
    useState<CashItemFormValues>(formStateFromProps);

  const [projectSearchName, setProjectSearchName] = useState<string>("");
  const [payeeSearchName, setPayeeSearchName] = useState<string>("");

  useEffect(() => {
    setFormState(formStateFromProps);
  }, [
    props.amountBank,
    props.payeeId,
    props.projectId,
    props.moneyCategories,
    props.name,
  ]);

  const {
    id,
    vabankBalance,
    paid,
    transactionOn,
    inheritedChildrenIds,
    isInheritedParent,
    inheritedParentId,
    moneyCategoryAncestorsNamesArray,
  } = props;

  const date = transactionOn;

  const dispatch = useDispatch();

  const [isReccuringDialogOpen, setIsReccuringDialogOpen] = useState(false);

  const moneyCategoryNames = moneyCategoryAncestorsNamesArray || [];
  const moneyCategory = moneyCategoryNames.length
    ? moneyCategoryNames.join(" > ")
    : "Click to set";

  let moneyCategoryClassName = props.classes.moneyCategory;
  if (!moneyCategoryNames.length) {
    moneyCategoryClassName += ` ${props.classes.moneyCategoryUndefined}`;
  }
  if (paid) {
    moneyCategoryClassName += ` ${props.classes.disabled}`;
  }

  const history = useHistory();

  const variant = createVariant(props);

  let inputClassesRealistic = variant.none;
  if (props.vabankBalance < 0) {
    inputClassesRealistic = variant.red;
  } else if (props.vabankBalance > 0 && props.vabankBalance < 30000) {
    inputClassesRealistic = variant.yellow;
  }

  const createColumnsGrid = (arr: any[]) =>
    arr.map((item, i) => {
      if (!item.props) {
        const { grow, margin, component } = item;
        return (
          <Grid
            item
            key={i}
            className={
              (grow ? props.classes.fullGridItem : "") +
              " " +
              (margin ? props.classes.margin : "")
            }
          >
            {component}
          </Grid>
        );
      }
      return (
        <Grid item key={i}>
          {item}
        </Grid>
      );
    });

  const isDateValid = moment(date).isValid();
  const isTaxableOnValid = moment(formState.taxableOn).isValid();

  const projects = useSelector(selectProjects);
  const payees = useSelector(selectPayees);
  const payeesRecords = useSelector((state: RootState) => state.payees);

  const payee: PayeeApiEntity | null = payeesRecords[formState.payeeId as any]
    ? payeesRecords[formState.payeeId as any]
    : null;

  const moneyCategories = useSelector(selectMoneyCategories);

  const isParent = isInheritedParent;
  const parentId = inheritedParentId;

  const onDateChange = useCallback(
    (value: any) => {
      setFormState({
        ...formState,
        isFromProps: false,
        date: value,
      });
    },
    [formState]
  );

  const onTaxableOnChange = useCallback(
    (value: any) => {
      setFormState({
        ...formState,
        isFromProps: false,
        taxableOn: value,
      });
    },
    [formState]
  );

  const onPayeeChange = useCallback(
    (value: any) => {
      setFormState({
        ...formState,
        isFromProps: false,
        payeeId: value,
      });
    },
    [formState]
  );

  const onProjectChange = useCallback(
    (value: any) => {
      setFormState({
        ...formState,
        isFromProps: false,
        projectId: value,
      });
    },
    [formState, payee, payees]
  );

  const onNameChange = useCallback(
    (value: any) => {
      setFormState({
        ...formState,
        isFromProps: false,
        name: value,
      });
    },
    [formState]
  );

  const onMoneyCategoryChange = useCallback(
    (value: any) => {
      setFormState({
        ...formState,
        isFromProps: false,
        moneyCategoryId: value,
      });
    },
    [formState]
  );

  const onAllBallanceChange = (
    amountNetto: any,
    amountNettoCalculation: string,
    amountBrutto: any,
    amountBruttoCalculation: string,
    amountVat: any,
    amountVatCalculation: string
  ) => {
    const change = {
      amountNetto,
      amountNettoCalculation: !!amountNettoCalculation
        ? amountNettoCalculation
        : amountNetto,
      amountBrutto,
      amountBruttoCalculation: !!amountBruttoCalculation
        ? amountBruttoCalculation
        : amountBrutto,
      amountVat,
      amountVatCalculation: !!amountVatCalculation
        ? amountVatCalculation
        : amountVat,
    };

    setFormState({
      ...formState,
      isFromProps: false,
      ...change,
    });
  };

  const onNettoBalanceChange = (
    amountNetto: any,
    amountNettoCalculation: string,
    calculateVat?: boolean,
    vatRate?: "0" | "5" | "8" | "23"
  ) => {
    if (calculateVat && vatRate) {
      const vatRateNumber = parseInt(vatRate);
      let netto = parseInt(amountNetto);
      let vat = Math.round(netto / 100) * vatRateNumber;
      let brutto = netto + vat;
      let bruttoAsString = brutto.toString();
      let vatAsString = vat.toString();

      onAllBallanceChange(
        amountNetto,
        amountNettoCalculation,
        brutto,
        bruttoAsString,
        vat,
        vatAsString
      );
    } else {
      const change = {
        amountNetto,
        amountNettoCalculation: !!amountNettoCalculation
          ? amountNettoCalculation
          : amountNetto,
      };

      setFormState({
        ...formState,
        isFromProps: false,
        ...change,
      });
    }
  };

  const onBruttoBalanceChange = (
    amountBrutto: any,
    amountBruttoCalculation: string
  ) => {
    const change = {
      amountBrutto,
      amountBruttoCalculation: !!amountBruttoCalculation
        ? amountBruttoCalculation
        : amountBrutto,
    };

    setFormState({
      ...formState,
      isFromProps: false,
      ...change,
    });
  };

  const onVatBalanceChange = (amountVat: any, amountVatCalculation: string) => {
    const change = {
      amountVat,
      amountVatCalculation: !!amountVatCalculation
        ? amountVatCalculation
        : amountVat,
    };

    setFormState({
      ...formState,
      isFromProps: false,
      ...change,
    });
  };

  const transformState = (formState: any) => {
    const { isFromProps, ...fields } = formState;

    const mapped = {
      ...fields,
      amountNetto: parseFloat(fields.amountNetto).toFixed(2),
      amountBrutto: parseFloat(fields.amountBrutto).toFixed(2),
      amountVat: parseFloat(fields.amountVat).toFixed(2),
      projectId: fields.projectId ? fields.projectId.toString() : undefined,
      payeeId: fields.payeeId ? fields.payeeId.toString() : undefined,
    };

    return _.omitBy(mapped, _.isUndefined);
  };

  useEffect(() => {
    if (formState.isFromProps) {
      return;
    }

    if (
      isEmpty(formState) ||
      isEqual(transformState(formState), transformState(prevFormState))
    ) {
      return;
    }

    if (parentId || isParent) {
      setIsReccuringDialogOpen(true);
    } else {
      const diff = objectDeepDifference(formState, prevFormState);

      dispatch(
        moneyItemsActions.updateSingle(
          mapFormToEntityProps({
            id: formState.id,
            ...diff,
          })
        )
      );
    }
    console.log("__formState___", formState);
  }, [formState]);

  const onCancelChange = () => {
    setIsReccuringDialogOpen(false);

    setFormState(prevFormState);
  };

  const onConfirmChange = (reccuring: boolean) => {
    setIsReccuringDialogOpen(false);

    const diff = objectDeepDifference(formState, prevFormState);

    if (reccuring) {
      dispatch(
        moneyItemsActions.updateSingleReccuring(
          mapFormToEntityProps({
            id: formState.id,
            ...diff,
          })
        )
      );
    } else {
      dispatch(
        moneyItemsActions.updateSingle(
          mapFormToEntityProps({
            id: formState.id,
            ...diff,
          })
        )
      );
    }

    setPrevFormState(formState);
  };

  const onLockClick = () => {
    dispatch(
      moneyItemsActions.updateSingle({
        id: props.id,
        paid: !paid,
      })
    );
  };

  const [isCreatingNewPayee, setIsCreatingNewPayee] = useState(false);
  const [isCreatingNewProject, setIsCreatingNewProject] = useState(false);

  const onNewPayeeClose = () => {
    setIsCreatingNewPayee(false);
  };

  const onNewPayeeSuccess = (newPayeeId: string) => {
    onPayeeChange(newPayeeId);

    setIsCreatingNewPayee(false);
  };

  const onNewProjectClose = () => {
    setIsCreatingNewProject(false);
  };

  const onNewProjectSuccess = (newProjectId: string) => {
    onProjectChange(newProjectId);

    setIsCreatingNewProject(false);
  };

  return (
    <Grid container className={variant[getColor(props)]}>
      {isCreatingNewPayee && (
        <CreatePayee
          name={payeeSearchName}
          onClose={onNewPayeeClose}
          onSuccess={onNewPayeeSuccess}
        />
      )}
      {isCreatingNewProject && (
        <CreateProject
          payeeId={props.payeeId}
          name={projectSearchName}
          onClose={onNewProjectClose}
          onSuccess={onNewProjectSuccess}
        />
      )}
      {isReccuringDialogOpen && (
        <ReoccuringItemDialog
          titleId={"Reocurring payment " + props.id}
          onConfirm={onConfirmChange}
          onClose={onCancelChange}
        />
      )}
      {createColumnsGrid([
        <DragButton lock={paid} listeners={props.listeners} />,
        <SettingsButton
          onClick={() => history.push("/home/money_items/" + props.id)}
          disabled={paid}
        />,
        <LockButton paid={paid} onClick={onLockClick} />,
        <HelpIcon id={id} />,
        <ParenthoodIcon
          parentId={parentId}
          childrenIds={inheritedChildrenIds}
        />,
        <DateInput
          value={date}
          disabled={paid}
          error={!isDateValid}
          errorMessage="Invalid date"
          onBlur={onDateChange}
        />,
        <DateInput
          value={formState.taxableOn}
          disabled={paid}
          error={!isTaxableOnValid}
          errorMessage="Invalid date"
          onBlur={onTaxableOnChange}
        />,
        <AutocompleteInput
          id={`payee-${id}`}
          className={props.classes.autoComplete}
          value={formState.payeeId}
          choices={payees}
          placeholder="Payee"
          attributeToChange="payeeId"
          disabled={paid}
          onChange={onPayeeChange}
          onCreateNewItem={() => setIsCreatingNewPayee(true)}
          onInputChange={(value: string) => setPayeeSearchName(value)}
        />,
        <AutocompleteInput
          id={`project-${id}`}
          className={props.classes.autoComplete}
          value={formState.projectId}
          choices={projects}
          placeholder="No Project"
          attributeToChange="projectId"
          disabled={paid}
          onChange={onProjectChange}
          onCreateNewItem={() => setIsCreatingNewProject(true)}
          onInputChange={(value: string) => setProjectSearchName(value)}
        />,
        <MoneyCategoryInput
          className={moneyCategoryClassName}
          valueToDisplay={moneyCategory}
          moneyCategories={moneyCategories}
          value={formState.moneyCategoryId}
          onChange={onMoneyCategoryChange}
          disabled={paid}
          moneyItemId={formState.id}
        />,
        {
          component: (
            <NameInput
              onBlur={onNameChange}
              name={props.name}
              value={formState.name}
              disabled={paid}
              id={props.id}
              isInheritedParent={props.isInheritedParent}
              inheritedParentId={props.inheritedParentId}
            />
          ),
          margin: true,
          grow: true,
        },
        {
          component: (
            <BalanceInput
              className={props.classes.numberInput}
              value={parseFloat(formState.amountNetto).toFixed(2)}
              disabled={paid}
              amountCalculation={formState.amountNettoCalculation}
              onBlur={onNettoBalanceChange}
              isNetto={true}
            />
          ),
          margin: true,
        },
        {
          component: (
            <BalanceInput
              className={props.classes.numberInput}
              value={parseFloat(formState.amountVat).toFixed(2)}
              disabled={paid}
              amountCalculation={formState.amountVatCalculation}
              onBlur={onVatBalanceChange}
            />
          ),
          margin: true,
        },
        {
          component: (
            <BalanceInput
              className={props.classes.numberInput}
              value={parseFloat(formState.amountBrutto).toFixed(2)}
              disabled={paid}
              amountCalculation={formState.amountBruttoCalculation}
              onBlur={onBruttoBalanceChange}
            />
          ),
          margin: true,
        },
        {
          component: (
            <CurrencyTextField
              variant="standard"
              className={
                props.classes.numberInput + " " + inputClassesRealistic
              }
              currencySymbol=" "
              value={vabankBalance}
              outputFormat="string"
              disabled
              digitGroupSeparator=" "
            />
          ),
          margin: true,
        },
        <AddiotionalActions id={id} />,
        <DeleteButton disabled={paid} record={props} />,
      ])}
    </Grid>
  );
};

export default withStyles(style as any)(CashItem);
