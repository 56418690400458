import { orderBy } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from '../root.reducer';

export const selectMoneyItems = createSelector(
    (state: RootState) => state.moneyItems,
    (moneyItems: any) => orderBy(moneyItems, 'order').map((item) => ({
        ...item,
        isVisible: true,
      }))
  )