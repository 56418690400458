import { DialogTitle, DialogContent, FormControl, RadioGroup, FormControlLabel, DialogActions, Button, Radio } from "@material-ui/core"
import { Dialog } from "material-ui"
import React from "react"
import { useState } from "react"

type Props = {
    titleId: string,
    onClose: () => void,
    onConfirm: (isReccuring: boolean) => void
}

export const ReoccuringItemDialog: React.FC<Props> = ({
    titleId,
    onClose,
    onConfirm
}) => {
    const [isRecurring, setIsRecurring] = useState("no");

    const handleClose = () => {
        onClose();
    };
    
    const handleRecurringChange = (event: any) => {
        setIsRecurring(event.target.value);
    };
    
    const handleConfirm = () => {
        onConfirm(isRecurring === 'yes')
    };

    return <Dialog open={true} onRequestClose={handleClose} aria-labelledby={titleId}>
        <DialogTitle id={titleId}>Modify recurring payment?</DialogTitle>
        <DialogContent>
            <FormControl component="fieldset">
                <RadioGroup
                    aria-label="recurring"
                    name="recurring"
                    className="group"
                    value={isRecurring}
                    onChange={handleRecurringChange}
                >
                    <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="This payment"
                    />
                    <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="This and following payments"
                    />
                </RadioGroup>
            </FormControl>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="secondary">
                Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
                OK
            </Button>
        </DialogActions>
    </Dialog>
}
