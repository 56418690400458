import React from 'react';
import {
  DndContext, 
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import {SortableItem} from './SortableItem';
import { useDispatch } from 'react-redux';
import { moneyItemsActions } from '../../model/actions/moneyItems.actions';
import { MoneyItemEntity } from '../../model/reducers/moneyItems/MoneyItemEntity';
import Infinite from 'react-infinite';

export const SortableList: React.FC<any> = ({ items }) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const dispatch = useDispatch()

  function handleDragEnd(event: any) {
    const {active, over} = event;
    
    if (active.id !== over.id) {
      dispatch(moneyItemsActions.reorder({ activeId: active.id, overId: over.id }));
    }
  }

  return (
    <DndContext 
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext 
        items={items}
        strategy={verticalListSortingStrategy}
      >
      <Infinite useWindowAsScrollContainer elementHeight={36}>
        {items.map((item: MoneyItemEntity, index: number) => {
            // resBal = resBal + parseFloat(item.realisticVal);

            // item.belowzerorealistic = parseFloat(resBal) < 0 ? true : false;

            if (!item.isVisible) {
              return null;
            }

            return (
              <SortableItem
                key={`item-${item.id}`}
                // index={index}
                {...item}
                // isParent={false}
                // color={item}
                // isBlocked={(paid) => handleChangeCheckbox(paid, cashitem)}
                // isBlocked={false}
                // addNext={(event) => addNext(event, cashitem)}
                // onEdit={() => handleEditCashItem(cashitem.id)}
              />
            );
          })}
      </Infinite>
      </SortableContext>
    </DndContext>
  );

}