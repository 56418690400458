import {
  DialogTitle,
  DialogContent,
  FormControl,
  RadioGroup,
  FormControlLabel,
  DialogActions,
  Button,
  Radio,
  Grid,
} from "@material-ui/core";
import { Dialog } from "material-ui";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectMoneyCategories } from "../../model/selectors/moneyCategories.selector";
import { MoneyCategorySelect } from "../MoneyCategorySelect";

type Props = {
  titleId: string;
  moneyCategoryId: string;
  onClose: () => void;
  onConfirm: (isReccuring: boolean, value: string) => void;
};

export const ReoccuringItemDialogWithMoneyCategory: React.FC<Props> = ({
  titleId,
  onClose,
  onConfirm,
  moneyCategoryId,
}) => {
  const [isRecurring, setIsRecurring] = useState("no");
  const [value, setValue] = useState(moneyCategoryId);

  useEffect(() => {
    setValue(moneyCategoryId);
  }, [moneyCategoryId]);

  const handleClose = () => {
    onClose();
  };

  const handleRecurringChange = (event: any) => {
    setIsRecurring(event.target.value);
  };

  const handleConfirm = () => {
    onConfirm(isRecurring === "yes", value);
  };

  const handleMoneyCategoryChange = (value: any) => {
      if (!value || !value[0]) {
          setValue("")
          return null
      }
    setValue(value[0].value);
  };

  const moneyCategoriesToChoose = useSelector(selectMoneyCategories);

  return (
    <Dialog open={true} onRequestClose={handleClose} aria-labelledby={titleId}>
      <DialogTitle id={titleId}>Modify recurring payment?</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item lg={6} style={{ minHeight: 300 }}>
            <MoneyCategorySelect
              value={value}
              onChange={handleMoneyCategoryChange}
              elements={moneyCategoriesToChoose}
              filter={false}
            />
          </Grid>
          <Grid item lg={6}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="recurring"
              name="recurring"
              className="group"
              value={isRecurring}
              onChange={handleRecurringChange}
            >
              <FormControlLabel
                value="no"
                control={<Radio />}
                label="This payment"
              />
              <FormControlLabel
                value="yes"
                control={<Radio />}
                label="This and following payments"
              />
            </RadioGroup>
          </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
