import React, { Component } from "react";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";

const prevmonthstart = moment().subtract(1, "months").startOf("month");
const prevmonthend = moment().subtract(1, "months").endOf("month");
const monthstart = moment().startOf("month");
const monthend = moment().endOf("month");
const nextmonthstart = moment().add(1, "months").startOf("month");
const nextmonthend = moment().add(1, "months").endOf("month");
const thirdmonthend = moment().add(3, "months").endOf("month");

const styles = (theme: any) => ({
  "@global": {
    ".DateInput_input": {
      fontSize: 16,
      padding: "11px 0px 8px 24px",
      lineHeight: "20px",
      width: "80%",
    },
    ".DateRangePickerInput__withBorder": {
      minWidth: 280,
    },
    ".DateRangePicker_picker": {
      zIndex: 999,
    },
  },
});

export type Props = {
  startOfMonth: any;
  endOfMonth: any;
  className: string;
  handleDateTrigger: any;
};

class Datefilter extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      startDate: props.startOfMonth,
      endDate: props.endOfMonth,
    };
    this.onDatesChange = this.onDatesChange.bind(this);
    this.renderDatePresets = this.renderDatePresets.bind(this);
  }

  onDatesChange({ startDate, endDate }: any) {
    this.setState({ startDate, endDate });
  }

  handleDateTrigger = _.debounce(() => {
    this.props.handleDateTrigger({
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    });
  }, 1);

  changeDates = ({ startDate, endDate }: any) => {
    this.setState({ startDate, endDate });
  };
  renderDatePresets() {
    return (
      <div className="buttonFilterBlock">
        <button
          className="buttonFilter"
          onClick={() =>
            this.props.handleDateTrigger({
              startDate: prevmonthstart,
              endDate: prevmonthend,
            })
          }
        >
          Previous month
        </button>
        <button
          className="buttonFilter"
          onClick={() =>
            this.props.handleDateTrigger({
              startDate: monthstart,
              endDate: monthend,
            })
          }
        >
          This month
        </button>
        <button
          className="buttonFilter"
          onClick={() =>
            this.props.handleDateTrigger({
              startDate: nextmonthstart,
              endDate: nextmonthend,
            })
          }
        >
          Next month
        </button>
        <button
          className="buttonFilter"
          onClick={() =>
            this.props.handleDateTrigger({
              startDate: monthstart,
              endDate: thirdmonthend,
            })
          }
        >
          3 months from this
        </button>
      </div>
    );
  }
  render() {
    return (
      <DateRangePicker
        isOutsideRange={() => false}
        displayFormat="DD/MM/YYYY"
        startDate={this.state.startDate} // momentPropTypes.momentObj or null,
        renderCalendarInfo={this.renderDatePresets}
        startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
        endDate={this.state.endDate} // momentPropTypes.momentObj or null,
        endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
        onDatesChange={this.onDatesChange} // PropTypes.func.isRequired,
        focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={(focusedInput: any) => {
          this.setState({ focusedInput });

          if (!focusedInput) {
            this.handleDateTrigger();
          }
        }} // PropTypes.func.isRequired,
      />
    );
  }
}
export default withStyles(styles)(Datefilter);
