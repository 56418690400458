import React from "react";
import { useHistory } from "react-router-dom";
import { CreateProject } from "./CreateProject";

export type Props = {
};

export const CreateProjectContainer: React.FC<Props> = () => {
    const history = useHistory();

    const onClose = () => {
        history.push('/home');
    }


  return (
    <CreateProject onSuccess={onClose} onClose={onClose} />
  );
};
