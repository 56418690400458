import { MoneyItemEntity } from "../model/reducers/moneyItems/MoneyItemEntity"
import HookRequest, { WebhookActionType } from "../service/HookRequest"

export const requestWebhook = (moneyItem: MoneyItemEntity, actionType: WebhookActionType) => {
    const parent = moneyItem.inheritedParentId || moneyItem.logicParentId;

    return HookRequest({
        name: moneyItem.name,
        value: moneyItem.amountBank,
        parent: parent ? parent.name : undefined,
    }, actionType)
}