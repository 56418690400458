import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import * as routes from "../constants/routes";
import Notifier, { openSnackbar } from "./Notifier/Notifier";
import AuthService from "../service/AuthService";

const SignInPage = ({ history }) => {
  return (
    <div>
      <SignInForm history={history} />
    </div>
  );
};
const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
  errorMSG: null,
};

class SignInForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    const { history } = this.props;
    const token = Boolean(localStorage.getItem("token"));
    if (token) {
      history.push(routes.HOME);
    }
  }

  onSubmit = (event) => {
    event.preventDefault();

    const { email, password } = this.state;

    const { history } = this.props;

    AuthService.login(email, password)
      .then(() => {
        localStorage.setItem("user-email", email);
        openSnackbar({ message: "Signed in" });
        this.setState(() => ({ ...INITIAL_STATE }));
        history.push(routes.HOME);
      })
      .catch((error) => {
        this.setState(byPropKey("error", error));
      });
    event.preventDefault();
  };

  render() {
    const { email, password, error, errorMSG } = this.state;

    const isInvalid = password === "" || email === "";

    return (
      <MuiThemeProvider>
        <div className="wrapper">
          <form onSubmit={this.onSubmit} className="LoginForm">
            <h1>Sign In</h1>
            <TextField
              type="text"
              hintText="Email Address"
              floatingLabelText="Email Address"
              value={email}
              onChange={(event) => this.setState(byPropKey("email", event.target.value))}
            />
            <TextField
              type="password"
              hintText="Password"
              floatingLabelText="Password"
              value={password}
              onChange={(event) => this.setState(byPropKey("password", event.target.value))}
            />
            <RaisedButton
              label="Sign In"
              className="FormBtn"
              type="submit"
              disabled={isInvalid}
              primary={true}
            />
            <div className="error">
              {errorMSG}
              {error && <span>{error.message}</span>}
            </div>
          </form>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withRouter(SignInPage);

export { SignInForm };
