import Rest from './Rest'

const get = async (url,lite,filters) => {
	if(filters === undefined) {
		filters = {}
	}

	const f = {}
	for (let filter in filters) {
		if(filter !== 'additionalParams') {
			const key = `filter[${filter}]`
			f[key] = filters[filter];
		}
	}

	try {
		const req = await Rest({
			method: 'GET',
			url,
			params: {
				lite: lite,
				...f,
				...filters['additionalParams']
			}
		})
		return req.data;
	} catch (error) {
		if ((error.response.status == 401 || error.response.status == 403) && Boolean(localStorage.getItem('token'))) {
			localStorage.removeItem('token');
			window.location.reload()
		} else {
			console.log('err', { error })
		}
	}
}

const getRaw = async (url,lite,params) => {
	try {
		return await Rest({
			method: 'GET',
			url,
			params: {
				lite: lite,
				...params
			}
		});
	} catch (error) {
		if ((error.response.status == 401 || error.response.status == 403) && Boolean(localStorage.getItem('token'))) {
			localStorage.removeItem('token');
			window.location.reload()
		} else {
			console.log('err', { error })
		}
	}
}

const getNames = async (url,lite,filters) => {

	try {
		const items = await get(url,lite,filters);
		return items.map( x => ({
			id: x.id,
			name: x.attributes.name,
		}))
	} catch (error) {
	}
}


export default (url,lite)=>({
	get: (...params)=> get(url,lite,...params),
	getRaw: (...params) => getRaw(url,lite,...params),
	getNames: (...params)=> getNames(url,lite,...params),
})
