import Alert from "@material-ui/lab/Alert";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { filtersActions } from "../../model/actions/filters.actions";
import { RootState } from "../../model/root.reducer";

export const FiltersAlert: React.FC<{}> = () => {
  const dispatch = useDispatch();

  const isDefault = useSelector((state: RootState) => state.filters.isDefault);

  if (isDefault) {
    return null;
  }

  return (
    <Alert severity="info" style={{ fontSize: 16 }}>
      Filtering is enabled. You can{" "}
      <a
        href="#"
        onClick={(event) => {
          event.preventDefault();
          dispatch(filtersActions.resetDetailFilters());
        }}
      >
        disable it
      </a>
      .
    </Alert>
  );
};
