import { createReducer } from "typesafe-actions";
import { denormalizeCollectionResponse } from "../../../common/denormalizeResponse";
import { WithId } from "../../../common/withId";
import { appActions } from "../../actions/app.actions";
import { projectsActions } from "../../actions/projects.actions";
import { ProjectApiEntity } from "./ProjectApiEntity";

export type ProjectsState = {
    [key: string]: WithId<ProjectApiEntity>;
};

export const projectsReducer = createReducer<ProjectsState>(
    {}
).handleAction(
    appActions.loaded,
    (
        state: ProjectsState,
        action: ReturnType<typeof appActions.loaded>
    ) => {
        const result = denormalizeCollectionResponse(action.payload.projects);

        return result
    }
).handleAction(
    projectsActions.createProject,
    (
        state: ProjectsState,
        action: ReturnType<typeof projectsActions.createProject>
    ) => {
        return {
            ...state,
            [action.payload.id]: {
                ...action.payload
            }
        }
    }
)
