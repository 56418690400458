import { createReducer } from "typesafe-actions";
import { appActions } from "../../actions/app.actions";
import { camelCase, mapKeys } from "lodash";

export type AppState = {
  isReady: boolean
};

export const appReducer = createReducer<AppState | null>({
    isReady: false
}).handleAction(
  appActions.ready,
  (
    state: AppState,
    action: ReturnType<typeof appActions.ready>
  ) => {
    return {
        ...state,
        isReady: true
    }
  }
);
