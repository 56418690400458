import { useState, useEffect } from 'react';

export default (
	initialState,
	asyncFunc,
	dependencies,
	run=true
) => {
	const [value, setValue] = useState(initialState)

	useEffect(() => {
		if(!run) return;
		asyncFunc().then(res => setValue(res))
	}, [JSON.stringify(dependencies), run])

	return value;
}