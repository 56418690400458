import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Slide, { SlideProps } from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { DUPLICATE_PAYMENT, EDIT_PAYMENT, HOME } from "../../constants/routes";
import { moneyItemsActions } from "../../model/actions/moneyItems.actions";
import { RootState } from "../../model/root.reducer";
import MoneyItems from "../../service/MoneyItems";
import handleSendNewPayment from "../../service/SendPayment";
import AddPaymentForm from "./AddPaymentForm";

const deepCopyObj = (obj: { open: boolean; repeat: boolean; values: { logicParentId: null; inheritedParentId: null; moneyAccountId: string; payeeId: null; projectId: null; moneyCategoryId: null; transactionOn: string; taxableOn: string; amountNetto: string; amountVat: string; amountBrutto: string; paid: boolean; name: string; }; error: { msg: undefined; repeat: undefined; date: undefined; name: undefined; }; }) => JSON.parse(JSON.stringify(obj));

const INIT_VALUE_STATE = {
  open: false,
  repeat: false,
  values: {
    logicParentId: null,
    inheritedParentId: null,
    moneyAccountId: "2",
    payeeId: null,
    projectId: null,
    moneyCategoryId: null,
    transactionOn: moment().format("YYYY-MM-DD"),
    taxableOn: moment().format("YYYY-MM-DD"),
    amountNetto: "",
    amountVat: "",
    amountBrutto: "",
    paid: false,
    name: "",
  },
  error: {
    msg: undefined,
    repeat: undefined,
    date: undefined,
    name: undefined,
  },
};

const styles = {
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  content: {
    flex: '1 1 auto',
    overflowY: 'auto',
    width: 'auto',
    padding: '0 16px',
    margin: 0
  }
};

function Transition(props: JSX.IntrinsicAttributes & SlideProps) {
  return <Slide direction="up" {...props} />;
}

const FullScreenDialog: React.FC<any> = React.forwardRef((props, ref) => {
  const {
    classes,
    payees,
    projects,
    moneyCategories
  } = props;
  const [state, setState] = React.useState(deepCopyObj(INIT_VALUE_STATE));
  const [tags, setTags] = React.useState<String[]>([])

  function updateTags(newTag: any){
    if(!tags.includes(newTag)){
      setTags(tags => [...tags, newTag])
    }
  }
  
  const editMatch = useRouteMatch<{
    id: string
  }>(EDIT_PAYMENT);

  const duplicateMatch = useRouteMatch<{
    id: string
  }>(DUPLICATE_PAYMENT);

  const isDuplicating = !!duplicateMatch

  const history = useHistory();

  let id: any;

  if (editMatch) {
    id = editMatch?.params.id !== "new" ? editMatch?.params.id : false;
  }

  if (duplicateMatch) {
    id = duplicateMatch?.params.id !== "new" ? duplicateMatch?.params.id : false;
  }

  React.useEffect(() => {
    if (!id) {
      setState(deepCopyObj(INIT_VALUE_STATE));
      return;
    }

    MoneyItems.getItem(id).then((data) => {
      setState((prevState: any) => ({
        ...prevState,
        values: {
          logicParentId: data["logic-parent-id"],
          inheritedParentId: isDuplicating ? id : data["inherited-parent-id"],
          moneyAccountId: data["money-account-id"],
          payeeId: data["payee-id"],
          projectId: data["project-id"],
          moneyCategoryId: data["money-category-id"],
          transactionOn: isDuplicating ? moment().format('YYYY-MM-DD') : data["transaction-on"],
          taxableOn: isDuplicating ? moment().format('YYYY-MM-DD') : data["taxable-on"],
          amountNetto: data["amount-netto"],
          amountVat: data["amount-vat"],
          amountBrutto: data["amount-brutto"],
          name: data["name"],
          paid: data["paid"],
        },
      }));
    });
  }, [id, isDuplicating]);

  React.useImperativeHandle(ref, () => ({
    handleClose,
  }));

  const handleClose = () => {
    setState(deepCopyObj(INIT_VALUE_STATE));

    history.push(HOME);
  };

  const handleKeyPress = (e: { which: number; target: { blur: () => void; }; }) => {
    if (e.which === 13) {
      e.target.blur();
      setTimeout(
        () => {
          handleSendNewPaymentFun();
        }
      );
    }
  };

  const setError = (key: string | number, msg: any) => {
    const error = { ...state.error };
    error[key] = msg;
    setState({
      ...state,
      error,
    });
  };

  const setValue = (key: string | number, value: any) => {
    const values = deepCopyObj(state.values);
    values[key] = value;
    setState({ ...state, values });
  };

  const clearValues = () => {
    setState(deepCopyObj(INIT_VALUE_STATE));
  };

  const dispatch = useDispatch()

  const handleSendNewPaymentFun = () => {
    MoneyItems.getItem(state.values.logicParentId).then((parent) => {
      handleSendNewPayment(
        state.values,
        tags, 
        {
          setError: setError,
          clearValues: clearValues,
          handleCloseDialog: _.noop
        },
        {
          edit: id,
          isDuplicating,
          parentName: parent ? parent.name : "",
        }
      )
        .then((result) => {
          if (!result) {
            return;
          }

          dispatch(moneyItemsActions.reload())
          history.push('/home');
        })
    });
  };

  const editCurrentPayment = useRouteMatch<{
    id: string
  }>(EDIT_PAYMENT)

  const ID = editCurrentPayment?.params.id
  const moneyItems = useSelector((state: RootState) => state.moneyItems);

  React.useEffect(() => {
    if(ID){
      if(moneyItems[ID]){
        let selectedBeforeTags = moneyItems[ID].tags
        selectedBeforeTags = selectedBeforeTags.filter(function(elem: any, index: any, self: any) {
          return index === self.indexOf(elem);
      })
        setTags(selectedBeforeTags)
      }
    } 
  }, [])

  const removeTagFromSelectedTags = (tagToRemove: string) => {
    setTags(tags => tags.filter(tag => tag !== tagToRemove ));
  };

  return (
    <Dialog
      maxWidth="lg"
      open={!!editMatch || !!duplicateMatch} 
      onClose={handleClose}
      onKeyPress={handleKeyPress as any}
      TransitionComponent={Transition}
      fullWidth
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton color="inherit" onClick={handleClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography color="inherit" className={classes.flex}>
            {!isDuplicating && (id ? "Edit Payment" : "New Payment")}
            {Boolean(id) && isDuplicating && "Duplicate payment"}
          </Typography>
          <Button color="inherit" onClick={handleSendNewPaymentFun}>
            Save
          </Button>
        </Toolbar>
      </AppBar>
      <AddPaymentForm
        state={state}
        props={props}
        className={classes.content}
        setState={(value: any) => setState(value)}
        setValue={setValue}
        initialValues={INIT_VALUE_STATE}
        setError={setError}
        payeeNames={payees}
        projects={projects}
        moneyCategories={moneyCategories}
        updateTags={updateTags}
        tags={tags}
        removeTagFromSelectedTags={removeTagFromSelectedTags}
      />
    </Dialog>
  );
});

export default withStyles(styles as any)(FullScreenDialog);
