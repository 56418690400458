import { createReducer } from "typesafe-actions";
import moment from "moment";
import { Moment } from "moment";
import {
  filtersActions,
  UpdateDetailFiltersActionPayload,
} from "../../actions/filters.actions";
import { appActions } from "../../actions/app.actions";

export type FiltersState = {
  startOfMonth: Moment;
  endOfMonth: Moment;
  search: string;
  isDefault: boolean;
  tags: string | null;
} & UpdateDetailFiltersActionPayload;

const filters = localStorage.getItem("filters");
const parsedFilters = filters ? JSON.parse(filters) : null;

const startOfMonth = moment().startOf("month");
const endOfMonth = moment(startOfMonth).add(1, "M");

const initialState = filters
  ? {
      ...parsedFilters,
      startOfMonth: moment(parsedFilters.startOfMonth),
      endOfMonth: moment(parsedFilters.endOfMonth),
    }
  : {
      startOfMonth,
      endOfMonth,
      payeeId: null,
      projectId: null,
      moneyCategories: [],
      search: "",
      moneyAccountId: null,
      amountFrom: null,
      amountTo: null,
      reccuringParents: false,
      reccuringChildren: false,
      reccuringOrphan: false,
      isDefault: true,
      tags: null
    };

export const filtersReducer = createReducer<FiltersState>(initialState)
  .handleAction(
    filtersActions.changeDateRange,
    (
      state: FiltersState,
      action: ReturnType<typeof filtersActions.changeDateRange>
    ) => ({
      ...state,
      startOfMonth: action.payload.startDate,
      endOfMonth: action.payload.endDate,
    })
  )
  .handleAction(
    filtersActions.changeSearch,
    (
      state: FiltersState,
      action: ReturnType<typeof filtersActions.changeSearch>
    ) => ({
      ...state,
      search: action.payload,
    })
  )
  .handleAction(
    filtersActions.updateDetailFilters,
    (
      state: FiltersState,
      action: ReturnType<typeof filtersActions.updateDetailFilters>
    ) => ({
      ...state,
      ...action.payload,
      isDefault: false
    })
  )
  .handleAction(
    filtersActions.resetDetailFilters,
    (
      state: FiltersState,
      action: ReturnType<typeof filtersActions.resetDetailFilters>
    ) => ({
      ...state,
      payeeId: null,
      projectId: null,
      moneyCategories: [],
      amountFrom: null,
      amountTo: null,
      recurringParents: false,
      recurringChildren: false,
      recurringOrphan: false,
      moneyAccountId: null,
      isDefault: true,
      tags: null
    })
  )
  .handleAction(
    appActions.loaded,
    (state: FiltersState, action: ReturnType<typeof appActions.loaded>) => {
      return {
        ...state,
        ...action.payload.urlFilters,
        startOfMonth: moment(action.payload.urlFilters.startOfMonth),
        endOfMonth: moment(action.payload.urlFilters.endOfMonth),
      };
    }
  );
