import axios, { AxiosRequestConfig } from 'axios'

export default async (params: AxiosRequestConfig) => {
	const token = localStorage.getItem('token');

	const request = await axios({
		...params,
		headers: {
			authorization: 'Bearer ' + token,
			Accept: 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json',
		}
	})

	return request.data;
}
