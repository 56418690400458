import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormGroup,
  InputLabel,
  Grid,
  FormControlLabel,
} from "@material-ui/core";
import { Checkbox, Dialog, TextField } from "material-ui";
import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { filtersActions } from "../../model/actions/filters.actions";
import { FiltersState } from "../../model/reducers/filters/filters.reducer";
import { RootState } from "../../model/root.reducer";
import { selectMoneyCategories } from "../../model/selectors/moneyCategories.selector";
import { selectPayees } from "../../model/selectors/payees.selector";
import { selectProjects } from "../../model/selectors/projects.selector";
import AutoCompleteInputWrapper from "../AutocompleteInput/AutoCompleteInputWrapper";
import { DialogTitleWithClose } from "../common/DialogTitleWithClose";
import MoneyAccounts from "../../service/MoneyAccountsService";
import { MoneyCategorySelect } from "../MoneyCategorySelect";
import { selectTags } from "../../model/selectors/tagsSelector";

export type FilterInputs = {
  moneyAccountId: string | null;
  payeeId: string | null;
  projectId: string | null;
  moneyCategories: any[] | null;
  amountFrom?: string;
  amountTo?: string;
  recurringParents: boolean;
  recurringChildren: boolean;
  recurringOrphan: boolean;
  tags: string | null;
};

export const DetailFilters: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const projects = useSelector(selectProjects);

  const tagNames = useSelector(selectTags);

  const payees = useSelector(selectPayees);

  const moneyCategoriesToChooseFrom = useSelector(selectMoneyCategories);

  const dispatch = useDispatch();

  const {
    payeeId,
    projectId,
    moneyCategories: selectedMoneyCategories,
    moneyAccountId,
    amountFrom,
    amountTo,
    recurringParents,
    recurringChildren,
    recurringOrphan,
    tags,
  }: FiltersState = useSelector((state: RootState) => {
    return state.filters;
  });

  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FilterInputs>({
    defaultValues: {
      moneyAccountId,
      payeeId,
      projectId,
      moneyCategories: selectedMoneyCategories,
      amountFrom: amountFrom as any,
      amountTo: amountTo as any,
      recurringParents,
      recurringChildren,
      recurringOrphan,
      tags,
    },
  });

  const handleClose = () => {
    onClose();
  };

  const onSubmit: SubmitHandler<FilterInputs> = (formInput: FilterInputs) => {
    console.log(formInput)

    dispatch(filtersActions.updateDetailFilters(formInput as any));

    handleClose();
  };

  const fields = watch();

  const handleReset = () => {
    reset();

    dispatch(filtersActions.resetDetailFilters());

    handleClose();
  };

  return (
    <Dialog open={true} aria-labelledby="form-dialog-title">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitleWithClose onClose={handleClose} id="form-dialog-title">
          Filter
        </DialogTitleWithClose>
        <DialogContent>
          <Box pt={3} pb={3}>
            <FormGroup>
              <InputLabel>Account</InputLabel>
              <Controller
                name="moneyAccountId"
                control={control}
                render={({ field }) => (
                  <AutoCompleteInputWrapper
                    {...field}
                    multi
                    classes="multi-input"
                    fetchDataFunction={MoneyAccounts.getNames}
                    onSelectLabel={field.onChange}
                    selectId="moneyAccounts"
                  />
                )}
              />
            </FormGroup>
          </Box>

          <Box pt={3} pb={3}>
            <FormGroup>
              <InputLabel>Payee</InputLabel>
              <Controller
                name="payeeId"
                control={control}
                render={({ field }) => (
                  <AutoCompleteInputWrapper
                    {...field}
                    classes="multi-input"
                    multi
                    selectItems={payees}
                    onSelectLabel={field.onChange}
                    selectId="payeeId"
                  />
                )}
              />
            </FormGroup>
          </Box>

          <Box pt={3} pb={3}>
            <FormGroup>
              <InputLabel>Project</InputLabel>
              <Controller
                name="projectId"
                control={control}
                render={({ field }) => (
                  <AutoCompleteInputWrapper
                    {...field}
                    multi
                    selectItems={projects}
                    onSelectLabel={field.onChange}
                    selectId="projectId"
                  />
                )}
              />
            </FormGroup>
          </Box>

          <Box pt={3} pb={3}>
            <FormGroup>
              <InputLabel>Tags</InputLabel>
              <Controller
                name="tags"
                control={control}
                render={({ field }) =>    
                  <AutoCompleteInputWrapper
                    {...field}
                    multi
                    selectItems={tagNames}
                    onSelectLabel={field.onChange}
                    selectId="tags"
                    />
                }
              />
            </FormGroup>
          </Box>

          <Box pt={3} pb={3}>
            <FormGroup>
              <InputLabel>Money Category</InputLabel>
              <Controller
                name="moneyCategories"
                control={control}
                render={({ field }) => {
                  return (
                    <MoneyCategorySelect
                      {...field}
                      onChange={(event: any) => {
                        if (!event || !event[0]) {
                          field.onChange("");
                          return null;
                        }
                        field.onChange(
                          event.map((item: any) => item.value).join(",")
                        );
                      }}
                      value={field.value}
                      elements={moneyCategoriesToChooseFrom}
                      filter={true}
                    />
                  );
                }}
              />
            </FormGroup>
          </Box>
          <Box pt={3} pb={3}>
            <FormGroup>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <InputLabel>Amount From</InputLabel>
                  <FormControl>
                    <Controller
                      name="amountFrom"
                      control={control}
                      render={({ field }) => (
                        <TextField type="text" {...field} />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>Amount To</InputLabel>
                  <FormControl>
                    <Controller
                      name="amountTo"
                      control={control}
                      render={({ field }) => (
                        <TextField type="text" {...field} />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </FormGroup>
          </Box>
          <Box pt={3} pb={3}>
            <FormGroup row>
              <FormControlLabel
                style={{ marginRight: 90 }}
                control={
                  <Controller
                    name="recurringParents"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Checkbox
                          checked={field.value as any}
                          onCheck={(e) => {
                            field.onChange((e.target as any).checked);
                          }}
                        />
                      );
                    }}
                  />
                }
                label="Recurring Parents"
              />
              <FormControlLabel
                style={{ marginRight: 90 }}
                control={
                  <Controller
                    name="recurringChildren"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Checkbox
                          checked={field.value as any}
                          onCheck={(e) => {
                            field.onChange((e.target as any).checked);
                          }}
                        />
                      );
                    }}
                  />
                }
                label="Recurring Children"
              />
              <FormControlLabel
                style={{ marginRight: 90 }}
                control={
                  <Controller
                    name="recurringOrphan"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Checkbox
                          checked={field.value as any}
                          onCheck={(e) => {
                            field.onChange((e.target as any).checked);
                          }}
                        />
                      );
                    }}
                  />
                }
                label="Recurring Orphan"
              />
            </FormGroup>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReset} color="secondary">
            Reset
          </Button>
          <Button type="submit" color="primary">
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
