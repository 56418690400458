import * as React from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import LockIcon from "@material-ui/icons/Lock";
import IconButton from "@material-ui/core/IconButton";

const LockButton = ({ paid, onClick, ...rest }) => {
  const handleClick = () => {
    if (onClick) {
      onClick(!paid);
    }
  };

  return (
    <IconButton {...rest} onClick={handleClick}>
      {paid ? <LockIcon /> : <CheckCircleIcon />}
    </IconButton>
  );
};

export default LockButton;
