import * as React from "react";
import { withRouter, useHistory } from "react-router-dom";

import AuthUserContext from "./AuthUserContext";
import * as routes from "./constants/routes";
import AuthService from "./service/AuthService";

const withAuthorization = (authCondition) => (Component) => {
  const WithAuthorization = () => {
    const history = useHistory();
    const token = Boolean(localStorage.getItem("token"));

    React.useEffect(() => {
      if (!token) {
        history.push(routes.SIGN_IN);
      }
    }, [token]);

    return (
      <AuthUserContext.Consumer>
        {(authUser) => {
          if (authUser) {
            if (!AuthService.hasPermissions(authUser)) {
              AuthService.logout();
              history.push(routes.SIGN_IN);
              return null;
            }
            return <Component />;
          }
          return null;
        }}
      </AuthUserContext.Consumer>
    );
  };

  return WithAuthorization;
};

export default withAuthorization;
