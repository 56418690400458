import AuditTrailsService from "../../service/AuditTrailsService";
import React, {useEffect, useState, useMemo} from "react";
import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import useInfiniteScroll from "react-infinite-scroll-hook";
import moment from "moment";
import SettingsButton from "../CashItems/CashItem/components/SettingsButton";
import MoneyItems from "../../service/MoneyItems";
import _ from "lodash";
import {useHistory} from "react-router-dom";
import Spiner from "../Spiner/Spiner";

const AuditView = () => {
  const [page, setPage] = useState<any>(1);
  const [totalPages, setTotalPages] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<any>(true);
  const [auditTrails, setAuditTrails] = useState<any>([]);
  const [moneyItems, setMoneyItems] = useState<any>([]);
  const [hasNextPage, setHasNextPage] = useState<any>(true);

  const history = useHistory();

  const loadAuditData = () => {
    setIsLoading(true);
    const previousScroll = { x: window.pageXOffset, y: window.pageYOffset }
    AuditTrailsService.getRaw({
      'filter[item-type]': 'MoneyItem',
      sort: '-created-at',
      'page[number]': page,
      'page[size]': 50
    }).then((response) => {
      setTotalPages(response.meta['page-count']);
      setAuditTrails(auditTrails.concat(response.data));
      const loadedMoneyItemIds = moneyItems.map((moneyItem: any) => moneyItem.id);
      const newMoneyItemIds = _.uniq(response.data.map((auditTrail: any) => auditTrail.attributes['item-id']));
      MoneyItems.get({
        id: _.difference(newMoneyItemIds, loadedMoneyItemIds),
      }).then(function(data) {
        setMoneyItems(moneyItems.concat(data));
      })
      setIsLoading(false);
      resetScroll(previousScroll);
    })
  }

  const increasePage = () => {
    setPage(page + 1);
  }

  const checkNextPage = () => {
    setHasNextPage(page < totalPages);
  }

  const resetScroll = (previousScroll: any) => {
    if(previousScroll !== {} && previousScroll.x !== null && previousScroll.y !== null) {
      window.scrollTo(previousScroll.x, previousScroll.y);
    }
  }

  const styleForTableRow = (auditTrail: any) => {
    return 'audit-trails-row-' + auditTrail.attributes.event;
  }

  const moneyItemForAuditTrail = (auditTrail: any) => {
    return moneyItems.find((moneyItem: any) => {
      return moneyItem.id === auditTrail.attributes['item-id'].toString();
    });
  }

  const moneyItemEditDisabled = (auditTrail: any) => {
    const moneyItem = moneyItemForAuditTrail(auditTrail);
    return (moneyItem === undefined || moneyItem.attributes.paid);
  }

  const editMoneyItem = (auditTrail: any) => {
    const moneyItem = moneyItemForAuditTrail(auditTrail);
    if(moneyItem) {
      history.push("/home/money_items/" + moneyItem.id)
    }
  }

  const [infiniteScrollRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: hasNextPage,
    onLoadMore: increasePage,
  })

  useEffect(loadAuditData, [page])
  useEffect(checkNextPage, [page, totalPages])

  const random = React.useMemo(() => Math.ceil(Math.random() * 6), []);

  const renderLoader = () => {
    return random === 1 ? (
      <img
        style={{ marginLeft: "auto", marginRight: "auto" }}
        src="/betoniarka.gif"
      />
    ) : (
      <Spiner />
    )
  }

  const renderResults = () => {
    if (auditTrails.length <= 0) {
      return <p>No audit trails</p>;
    }

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Time</TableCell>
              <TableCell>Event</TableCell>
              <TableCell>Whodunnit</TableCell>
              <TableCell>Changeset</TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {auditTrails.map((auditTrail: any) => (
              <TableRow key={auditTrail.id} className={styleForTableRow(auditTrail)}>
                <TableCell>{moment(auditTrail.attributes['created-at']).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                <TableCell>{auditTrail.attributes['event']}</TableCell>
                <TableCell>{auditTrail.attributes['whodunnit']}</TableCell>
                <TableCell>{auditTrail.attributes['changeset']}</TableCell>
                <TableCell>
                  <SettingsButton disabled={moneyItemEditDisabled(auditTrail)} onClick={() => { editMoneyItem(auditTrail) }} />
                </TableCell>
              </TableRow>
            ))}
            {(isLoading || hasNextPage) && <TableRow ref={infiniteScrollRef}>
              <TableCell>Loading...</TableCell>
            </TableRow>}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <Grid container className="audit-container">
      {isLoading ? renderLoader() : renderResults()}
    </Grid>
  )
}

export default AuditView;