import _ from "lodash";
import moment from "moment";
import { all, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { requestWebhook } from "../../common/requestWebhook";
import { transformToApiRequestBody } from "../../common/transformToApiRequestBody";
import MoneyCategoryService from "../../service/MoneyCategoryService";
import MoneyItems from "../../service/MoneyItems";
import PayeeService from "../../service/PayeeService";
import ProjectService from "../../service/ProjectService";
import PeopleService from "../../service/PeopleService";
import DealsService from "../../service/DealsService";
import ProjectGroupsService from "../../service/ProjectGroupsService";
import { toastsActions } from "../../toasts/toasts.actions";
import { appActions } from "../actions/app.actions";
import { filtersActions } from "../actions/filters.actions";
import { moneyItemsActions } from "../actions/moneyItems.actions";
import { RootState } from "../root.reducer";
import { FiltersState } from "../reducers/filters/filters.reducer";
import TagsService from "../../service/TagsService";

export function* loadStartingState(): any {
  const [projects, payees, moneyCategories, people, deals, projectGroups, tags] =
    yield all([
      ProjectService.get(),
      PayeeService.get(),
      MoneyCategoryService.get(),
      PeopleService.get(),
      DealsService.get(),
      ProjectGroupsService.get(),
      TagsService.get()
    ]);

  const filtersFromLocalStorage = localStorage.getItem("filters");
  const parsedFilters = filtersFromLocalStorage
    ? JSON.parse(filtersFromLocalStorage)
    : null;

  const startOfMonth = moment().startOf("month");
  const endOfMonth = moment(startOfMonth).add(1, "M");

  const initialState = filtersFromLocalStorage
    ? {
        ...parsedFilters,
        startOfMonth: moment(parsedFilters.startOfMonth),
        endOfMonth: moment(parsedFilters.endOfMonth),
      }
    : {
        startOfMonth,
        endOfMonth,
        payeeId: null,
        projectId: null,
        moneyCategories: [],
        search: "",
        moneyAccountId: null,
        amountFrom: null,
        amountTo: null,
        reccuringParents: false,
        reccuringChildren: false,
        reccuringOrphan: false,
        isDefault: true,
        tags: null
      };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const filtersSearchParams = urlSearchParams.get("filters")
    ? urlSearchParams.get("filters")?.toString()
    : "{}";

  const urlFilters = _.mergeWith(
    {},
    initialState,
    JSON.parse(filtersSearchParams!.toString()),
    (a, b) => (b === null ? a : undefined)
  );

  yield put(
    appActions.loaded({
      projects,
      payees,
      moneyCategories,
      people,
      deals,
      projectGroups,
      urlFilters,
      tags
    })
  );

  yield all([fetchDatabaseData(), getTotalBalance()]);
}

function buildRange(from: string | null, to: string | null) {
  let result = "";

  if (!from && !to) {
    return undefined;
  }

  if (from) {
    result += from;
  }

  result += "*";

  if (to) {
    result += to;
  }

  return result;
}

function buildReccuringFilter(filters: FiltersState) {
  const { recurringChildren, recurringOrphan, recurringParents } = filters;

  const recurringAgreggated = [
    recurringChildren && "children",
    recurringOrphan && "orphan",
    recurringParents && "children",
  ].filter((value) => !!value).join(",");

  return !!recurringAgreggated ? recurringAgreggated : undefined
}

function* fetchDatabaseData(): any {
  const state: RootState = yield select();

  const search = state.filters.search;

  const startDate = moment(state.filters.startOfMonth).format("YYYY-MM-DD");
  const endDate = moment(state.filters.endOfMonth).format("YYYY-MM-DD");
  if (startDate === "Invalid date" || endDate === "Invalid date") return;

  const payeeId = state.filters.payeeId;
  const projectId = state.filters.projectId;
  const moneyCategories = state.filters.moneyCategories;
  const tags = state.filters.tags

  let payload = _.omitBy(
    {
      search,
      range: `${startDate}*${endDate}`,
      "payee-id": payeeId,
      "project-id": projectId,
      money_category_id: moneyCategories ? moneyCategories : undefined,
      amount: buildRange(state.filters.amountFrom, state.filters.amountTo),
      "money-account": state.filters.moneyAccountId || undefined,
      recurring: buildReccuringFilter(state.filters),
      tags: tags,
    },
    _.isUndefined
  );

  if (!payload.money_category_id) {
    delete payload.money_category_id;
  }

  let items = yield MoneyItems.get(payload);

  if (!Boolean(items)) items = [];

  if (
    items.length === 0 &&
    (Boolean(state.filters.payeeId) ||
      Boolean(state.filters.projectId) ||
      Boolean(state.filters.moneyCategories))
  ) {
    yield put(
      toastsActions.show(
        "No matching Items to display, please refine your filter / search criteria.",
        "info"
      )
    );
  }

  yield getTotalBalance();

  yield put(toastsActions.show("Updated", "success", 3000));
  yield put(moneyItemsActions.loadSuccess(items));

  localStorage.setItem("filters", JSON.stringify(state.filters));

  yield put(appActions.ready());
}

export function* getTotalBalance(): any {
  const totalBalance = yield MoneyItems.getTotalBalance();

  yield put(appActions.loadBalanceSuccess(totalBalance));
}

export function* addNewPayment(
  action: ReturnType<typeof moneyItemsActions.updateSingle>
): any {
  yield MoneyItems.update({
    id: action.payload.id,
    ...transformToApiRequestBody(action.payload),
  });

  yield put(moneyItemsActions.reload());
}

export function* updateSingle(
  action: ReturnType<typeof moneyItemsActions.updateSingle>
): any {
  if (!_.isNil(action.payload.paid)) {
    yield put(
      moneyItemsActions.updateSingleSuccessful({
        ...action.payload,
        id: action.payload.id!,
      })
    );
  }

  yield MoneyItems.update({
    id: action.payload.id,
    ...transformToApiRequestBody(action.payload),
  });

  yield getTotalBalance();

  if (!_.isNil(action.payload.paid)) {
    return;
  }

  yield put(moneyItemsActions.reload());

  requestWebhook(action.payload as any, "edit");
}

export function* updateSingleReccuring(
  action: ReturnType<typeof moneyItemsActions.updateSingleReccuring>
): any {
  yield MoneyItems.recurringUpdate({
    id: action.payload.id,
    ...transformToApiRequestBody(action.payload),
  });

  yield put(moneyItemsActions.reload());

  requestWebhook(action.payload as any, "edit");
}

export function* deleteSingle(
  action: ReturnType<typeof moneyItemsActions.deleteSingle>
): any {
  const currentState: RootState = yield select();
  const currentItem = currentState.moneyItems[action.payload.itemId];

  yield MoneyItems.deleteItem(action.payload.itemId);
  // TODO: should we update parent?

  yield put(moneyItemsActions.reload());

  requestWebhook(currentItem as any, "delete");
}

export function* deleteSingleReccuring(
  action: ReturnType<typeof moneyItemsActions.deleteSingleReccuring>
): any {
  yield MoneyItems.recurringDeleteItem(action.payload.itemId);

  const currentState: RootState = yield select();
  const currentItem = currentState.moneyItems[action.payload.itemId];

  yield put(moneyItemsActions.reload());

  requestWebhook(currentItem as any, "delete");
}

export function* reorder(
  action: ReturnType<typeof moneyItemsActions.reorder>
): any {
  const currentState: RootState = yield select();

  const currentItem = currentState.moneyItems[action.payload.activeId];
  const overItem = currentState.moneyItems[action.payload.overId];

  const newDate = overItem.transactionOn;

  const payload = {
    transactionOn: newDate,
    costOn: newDate,
    incomeOn: newDate,
  };

  yield MoneyItems.update({
    id: action.payload.activeId,
    ...transformToApiRequestBody(payload),
  });

  yield put(moneyItemsActions.reload());

  requestWebhook(currentItem as any, "edit");
}

export function* appSaga(): any {
  return yield all([
    takeEvery(appActions.startApp, loadStartingState),
    // takeEvery(moneyItemsActions.addNewPayment, addNewPayment),
    takeEvery(moneyItemsActions.updateSingle, updateSingle),
    takeEvery(moneyItemsActions.updateSingleReccuring, updateSingleReccuring),
    takeEvery(moneyItemsActions.deleteSingle, deleteSingle),
    takeEvery(moneyItemsActions.deleteSingleReccuring, deleteSingleReccuring),
    takeEvery(moneyItemsActions.reorder, reorder),
    takeLatest(
      [
        filtersActions.changeDateRange,
        filtersActions.changeSearch,
        filtersActions.updateDetailFilters,
        filtersActions.resetDetailFilters,
        moneyItemsActions.reload,
      ],
      fetchDatabaseData
    )
  ]);
}
