import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { moneyFormat } from './formats';

function truncateString(str, num) {
  if (num == null || num == 0 || str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}


const BarChart = ({currency, data, labelLength, opts, abs, ...rest}) => {
    const sorted = data.sort(function(a, b) {
      if (abs) {
        return (Math.abs(parseFloat(a.value)) < Math.abs(parseFloat(b.value))) ? 1 : -1
      } else {
        return (parseFloat(a.value) < parseFloat(b.value)) ? 1 : -1
      }
    });

  const serializedData = {
  	labels: sorted.map((element) => truncateString(element.label, labelLength)),
    fullLabels: sorted.map((element) => element.label),
    datasets: [
      {
        label: currency,
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: sorted.map((element) => abs ? Math.abs(element.value) : element.value),
      }
    ],
  }

  const options = {
    scales: {
      xAxes: [{
        ticks: {
          userCallback: (value) => moneyFormat(value, currency),
        }
      }]
    },
    tooltips: {
      callbacks: {
        title: (tooltipItem, data) => data.fullLabels[tooltipItem[0].index],
        label: (tooltipItem, data) => moneyFormat(tooltipItem.xLabel, currency),
      }
    },
    ...opts
  }

  return (
    <HorizontalBar data={serializedData} options={options} {...rest} />
  );
};

export default BarChart;
