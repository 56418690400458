import React from 'react'
import { TextField } from '@material-ui/core';

const TextInput: React.FC<any> = ({
	name,
	defaultValue,
	onBlur,
	disabled,
	type,
	className,
	step,
	value,
	...rest
}) => {

	return (
		<TextField
			className={className}
			name={name}
			onBlur={onBlur}
			defaultValue={defaultValue}
			disabled={disabled}
			type={type}
			step={step}
			value={value}
			fullWidth
			{...rest}
		/>
	)
}

export default TextInput;
