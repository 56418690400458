import { SagaIterator } from 'redux-saga';
import { all, put, takeEvery } from 'redux-saga/effects';
import { toastsActions } from '../../toasts/toasts.actions';
import { payeesActions } from '../actions/payees.actions';

export function* createPayeeSaga(action: ReturnType<typeof payeesActions.createPayee>): SagaIterator<any> {
  yield put(toastsActions.show('Payee ' + action.payload.name + ' created', 'success'))
}

export function* payeeSaga(): SagaIterator<any> {
  yield all([
    takeEvery(payeesActions.createPayee, createPayeeSaga)
  ]);
}
