import React from "react";
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { Calculator } from "./calculator/Calculator";
import { useState } from "react";
import { CalculatorModalInput } from "./calculator/CalculatorModal";

const BalanceInput: React.FC<any> = React.memo(({ disabled, amountCalculation: amountCalculationProp, className, value: valueProp = "", onBlur, isNetto }) => {
  const [value, setValue] = React.useState(valueProp);
  const [amountCalculation, setAmountCalculation] = useState(amountCalculationProp);

  React.useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  const handleBlur = () => {
    if (value.length < 1) {
      alert("Invalid value - reverting to the original!");
      setValue(valueProp)
    } else {
      if (value !== valueProp) {
        onBlur(value, value)
        setAmountCalculation(value)
      }
    }
  };
  
  const handleChange = (event: any, value: any) => {
    setValue(value);
  }

  const handleCalculatorChange = ({ amountBank, amountCalculation, calculateVat, vatRate = "23" }: CalculatorModalInput) => {
    
    console.log('amountBank', amountBank)
    console.log('amountCalculation', amountCalculation)

    if(isNetto && calculateVat){
      onBlur(amountBank, amountCalculation, calculateVat, vatRate)
    } else {
      setValue(amountBank)
      setAmountCalculation(amountCalculation)
      if (amountBank.length < 1) {
        alert("Invalid value - reverting to the original!");
        setValue(valueProp)
      } else {
        onBlur(amountBank, amountCalculation)
      }
    }

  }

  return (
    <>
      <CurrencyTextField
        variant="standard"
        value={value}
        currencySymbol=" "
        outputFormat="string"
        onChange={handleChange}
        onBlur={handleBlur}
        digitGroupSeparator=" "
        disabled={disabled}
        className={className}
      />
      <Calculator amountBank={value} amountCalculation={amountCalculation} onChange={handleCalculatorChange} disabled={disabled} isNetto={isNetto}/>
    </>
  );
});

export default BalanceInput;
