import * as React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import RepeatIcon from "@material-ui/icons/Repeat";
import Tooltip from "../../../Tooltip";
import moment from "moment";
import MoneyItems from "../../../../service/MoneyItems";
import TopParentIcon from "./TopParentIcon";

const style = (theme) => ({
  root: {
    ...theme.components.iconButton,
    display: "flex",
    "justify-content": "center",
    "align-items": "center",
  },
  icon: {
    fill: theme.palette.action.active,
  },
});

function ParenthoodIcon({ classes, parentId, childrenIds }) {
  const [show, setShow] = React.useState(false);
  const [data, setData] = React.useState();

  React.useEffect(() => {
    if (show && !data && parentId) {
      MoneyItems.getItem(parentId).then((data) => {
        setData(data);
      });
    }
  }, [data, show, parentId]);

  const hasChildren = childrenIds && childrenIds.length > 0;

  if (!parentId && !hasChildren) {
    return <div className={classes.root} />;
  }

  if (hasChildren) {
    return <TopParentIcon childrenIds={childrenIds} />
  }

  const titleWithParentData = data ? (
    <div>
      <Typography variant="subtitle2">Parent:</Typography>
      <Typography>Date: {moment(data["transaction-on"]).format("DD/MM/YYYY")}</Typography>
      <Typography>Name: {data.name}</Typography>
    </div>
  ) : (
    "Loading"
  );

  return (
    <Tooltip show={show} setShow={setShow} title={titleWithParentData}>
      <div className={classes.root}>
        <RepeatIcon className={classes.icon} />
      </div>
    </Tooltip>
  );
}

ParenthoodIcon.propTypes = {
  classes: PropTypes.any,
};

export default withStyles(style)(ParenthoodIcon);
