import Rest from "./Rest";
import Endpoints from "../constants/Endpoints";

const getAlert = async () => {
  const result = await Rest({
    method: "GET",
    url: `${Endpoints.syncRuns}/alert`,
  });
  return result;
};

export default { getAlert };
