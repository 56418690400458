import React, { useEffect } from "react";
import { ReoccuringItemDialogWithMoneyCategory } from "../../../ReoccuringItemDialog/ReoccuringItemDialogWithMoneyCategory";
import { moneyItemsActions } from "../../../../model/actions/moneyItems.actions";
import { useDispatch } from "react-redux";

const MoneyCategoryInput: React.FC<any> = React.memo(({
  className,
  disabled,
  value: valueProp = "",
  valueToDisplay,
  onChange,
  moneyItemId
}) => {
  const [value, setValue] = React.useState<string>(valueProp);
  const [prevValue, setPrevValue] = React.useState<string>(valueProp)

  useEffect(() => {
    setValue(valueProp);
    setPrevValue(valueProp);
  }, [valueProp]);

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  const handleClick = () => {
    if (disabled) {
        return;
    }
    setOpen(true);
  };

  // const titleId = `money-category-dialog-${record.id}-title`;

  const dispatch = useDispatch();

  const onCancelChange = () => {
    setOpen(false);

    setValue(prevValue)
  }

  const onConfirmChange = (reccuring: boolean, newValue: string) => {
    setOpen(false);

    if (reccuring) {
      dispatch(moneyItemsActions.updateSingleReccuring({
        id: moneyItemId,
        moneyCategoryId: parseInt(newValue, 10),
      }))
    } else {
      dispatch(moneyItemsActions.updateSingle({
        id: moneyItemId,
        moneyCategoryId: parseInt(newValue, 10) 
      }))
    }

    setPrevValue(newValue);
  }

  return (
    <div>
      <div className={className} onClick={handleClick} style={{ color: disabled ? '#bbb' : undefined }}>
        {valueToDisplay}
      </div>
      {open && <ReoccuringItemDialogWithMoneyCategory titleId="moneyCategoryChange" moneyCategoryId={value} onConfirm={onConfirmChange} onClose={onCancelChange}  />}
    </div>
  );
});

export default MoneyCategoryInput;
