import { createReducer } from "typesafe-actions";
import { toastsActions, ToastType } from "./toasts.actions";

export type Toast = {
  id: string;
  message: string;
  duration: number;
  type: ToastType;
  isHiding: true;
}

export type ToastsState = {
  items: Toast[];
};

export const toastsReducer = createReducer<ToastsState>({
  items: []
})
  .handleAction(toastsActions.show, (state: ToastsState, action: ReturnType<typeof toastsActions.show>) => {
    return {
      ...state,
      items: [action.payload, ...state.items]
    }
  })
  .handleAction(toastsActions.hide, (state: ToastsState, action: any) => {
    return {
      ...state,
      items: state.items.filter((item: Toast) => item.id !== action.payload.id)
    }
  })
  .handleAction(toastsActions.prepareHiding, (state: ToastsState, action: any) => {
    return {
      ...state,
      items: state.items.map((item: Toast) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            isHiding: true
          };
        }

        return item;
      })
    }
  });