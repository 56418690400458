import React, { useState } from 'react'
import { withStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import MuiHelpIcon from '@material-ui/icons/Help';
import Tooltip from '../../../Tooltip';
import useAsync from '../../../../hooks/useAsync';
import MoneyItems from '../../../../service/MoneyItems';

const style = theme => ({
	root:{
		...theme.components.iconButton,
		display: "flex",
		"justify-content": "center",
		"align-items": 'center'
	},
	icon: {
		fill: theme.palette.action.active
	},
})

function HelpIcon({
	classes,
	id
}) {
	const [show, setShow] = useState(false);

	const data = useAsync({}, async () => MoneyItems.info(id), [], show);

	return (
		<Tooltip
			show={show}
			setShow={setShow}
			title={
				<div>
					<Typography>Payee: {data['payee']}</Typography>
					<Typography>Project: {data['project']}</Typography>
					<Typography>Money Category: {data['money_category']}</Typography>
					<Typography>Logic parent: {data['logic_parent']}</Typography>
					<Typography>Inherited parent: {data['inherited_parent']}</Typography>
				</div>
			}
		>
			<div className={classes.root}>
				<MuiHelpIcon
					className={classes.icon}
				/>
			</div>
		</Tooltip>
	)
}

HelpIcon.propTypes = {
	classes: PropTypes.any,
}

export default withStyles(style)(HelpIcon);