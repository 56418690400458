import { createReducer } from "typesafe-actions";
import { denormalizeCollectionResponse } from "../../../common/denormalizeResponse";
import { WithId } from "../../../common/withId";
import { appActions } from "../../actions/app.actions";
import { projectGroupsActions } from "../../actions/project-groups.actions";
import { projectsActions } from "../../actions/projects.actions";
import { ProjectGroupApiEntity } from "./ProjectGroupApiEntity";

export type ProjectGroupState = {
  [key: string]: WithId<ProjectGroupApiEntity>;
};

export const projectGroupsReducer = createReducer<ProjectGroupState>({}).handleAction(
  appActions.loaded,
  (
    state: ProjectGroupState,
    action: ReturnType<typeof appActions.loaded>
  ) => {
    const result = denormalizeCollectionResponse(action.payload.projectGroups);

    return result;
  }
);
