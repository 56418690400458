import Dialog from "@material-ui/core/Dialog";
import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import {
  Box,
  Button,
  DialogActions,
  FormControl,
  FormGroup,
  FormHelperText,
  Input,
  InputLabel,
} from "@material-ui/core";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { createErrorHandler } from "../../../../../common/createErrorHandler";
import { DialogTitleWithClose } from "../../../../common/DialogTitleWithClose";
import { useEffect } from "react";
import { round, evaluate } from "mathjs";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

type Props = {
  amountBank: string;
  amountCalculation: string;
  onClose: () => void;
  onSuccess: (input: CalculatorModalInput) => void;
  isNetto: boolean;
};

export type CalculatorModalInput = {
  amountCalculation: string;
  amountBank: string;
  calculateVat: boolean;
  vatRate: "0" | "5" | "8" | "23";
};

const validationSchema = yup.object().shape({
  amountCalculation: yup.string().required().label("Formula"),
  amountBank: yup.string().required().label("Result"),
  calculateVat: yup.boolean(),
  vatRate: yup.string(),
});

export const CalculatorModal: React.FC<Props> = ({
  amountBank,
  amountCalculation,
  onClose,
  onSuccess,
  isNetto,
}) => {
  const {
    control,
    handleSubmit,
    setError,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<CalculatorModalInput>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      amountCalculation,
      amountBank,
      vatRate: '23'
    },
  });
  const dispatch = useDispatch();

  const handleError = createErrorHandler<CalculatorModalInput>(
    setError,
    dispatch
  );

  const onSubmit: SubmitHandler<CalculatorModalInput> = (formInput) => {
    onSuccess(formInput);
  };

  const fields = watch();

  useEffect(() => {
    try {
      const result = round(evaluate(fields.amountCalculation), 2);

      setValue("amountBank", result);

      clearErrors("amountCalculation");
    } catch (error) {
      setError(
        "amountCalculation",
        {
          type: "invalid",
          message: "Invalid formula",
        },
        {
          shouldFocus: false,
        }
      );
    }
  }, [fields.amountCalculation]);

  const netto = parseInt(fields.amountBank);
  const vat = Math.round(netto / 100) * parseInt(fields.vatRate);
  const vatFormatted = vat.toLocaleString();
  const brutto = netto + vat;
  const bruttoFormatted = brutto.toLocaleString();

  return (
    <Dialog maxWidth="md" fullWidth open={true} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitleWithClose id="dialog-title" onClose={onClose}>
          Calculator
        </DialogTitleWithClose>
        <DialogContent>
          <Box pt={2} pb={2}>
            {" "}
            <FormGroup>
              <InputLabel>Formula*</InputLabel>
              <FormControl>
                <Controller
                  name="amountCalculation"
                  control={control}
                  render={({ field }) => (
                    <Input
                      type="text"
                      {...field}
                      error={Boolean(errors.amountCalculation)}
                    />
                  )}
                />
              </FormControl>
              <FormHelperText error>
                {errors.amountCalculation && errors.amountCalculation.message}
              </FormHelperText>
            </FormGroup>
          </Box>
          <Box pt={2} pb={2}>
            <FormGroup>
              <InputLabel>Result</InputLabel>
              <FormControl>
                <Controller
                  name="amountBank"
                  control={control}
                  render={({ field }) => (
                    <Input
                      type="text"
                      disabled
                      {...field}
                      error={Boolean(errors.amountBank)}
                      style={{ color: "green" }}
                    />
                  )}
                />
              </FormControl>
            </FormGroup>
            <FormHelperText error>
              {errors.amountBank && errors.amountBank.message}
            </FormHelperText>
          </Box>
          {isNetto && (
            <Box pt={2} pb={2}>
              <Box display="flex">
                <Controller
                  name="calculateVat"
                  control={control}
                  render={({ field }) => (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox {...field} edge="start" style={{ width: "40px" }} />
                        }
                        name="checketVatCalculate"
                        label={`Calculate VAT (${vatFormatted}) and brutto (${bruttoFormatted}) automatically using this rate:`}
                      />
                    </FormGroup>
                  )}
                />
                <Controller
                  name="vatRate"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="0%"
                      />
                      <FormControlLabel
                        value="5"
                        control={<Radio />}
                        label="5%"
                      />
                      <FormControlLabel
                        value="8"
                        control={<Radio />}
                        label="8%"
                      />
                      <FormControlLabel
                        value="23"
                        control={<Radio />}
                        label="23%"
                      />
                    </RadioGroup>
                  )}
                />
              </Box>
              <FormHelperText error>
                {errors.calculateVat && errors.calculateVat.message}
              </FormHelperText>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="primary">
            Insert
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
