import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import withAuthorization from "../withAuthorization";
import { fieldValidatorCore } from "react-validation-framework";
import Filters from "./Filters/Filters";
import IconButton from "@material-ui/core/IconButton";
import ViewList from "@material-ui/icons/ViewList";
import ShowChart from "@material-ui/icons/ShowChart";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import ChartView from "./ChartView/ChartView";
import Alert from "./Alert/Alert";
import ButtonAppBar from "./ButtonAppBar/ButtonAppBar";
import SyncRunService from "../service/SyncRunService";
import { GridView } from "./GirdView/GridView";
import { Toasts } from "../toasts/toasts";
import { FiltersAlert } from "./Filters/FiltersAlert";
import AuditView from "./AuditView/AuditView";

fieldValidatorCore.addSupport(
  "TextField",
  (event: { target: { value: any; }; }[]) => event[0].target.value,
  (callback: (arg0: any, arg1: undefined, arg2: any) => any, args: { target: { value: any; }; }[]) => callback(args[0], undefined, args[0].target.value),
  "error"
);

class Home extends Component<any, any> {
  queue = [];
  child = null;

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      gridView: true,
      chartView: false,
      auditView: false,
      sync_alert: null
    };

    this.getSyncAlert();
  }

  getSyncAlert = () => {
    SyncRunService.getAlert().then(({ sync_alert }) => {
      this.setState({
        ...this.state,
        sync_alert,
      });
    });
  };

  handleGridView = () => {
    this.setState({
      gridView: true,
      chartView: false,
      auditView: false,
    });
  };

  handleChartView = () => {
    this.setState({
      gridView: false,
      chartView: true,
      auditView: false,
    });
  };

  handleAuditView = () => {
    this.setState({
      gridView: false,
      chartView: false,
      auditView: true,
    });
  };

  render() {
    let buttonClass: string = '';
    if (this.state.gridView) {
      buttonClass = ["button", "active"].join(" ");
    } else {
      buttonClass = ["button"].join(" ");
    }
    let buttonClassChart: string = '';
    if (this.state.chartView) {
      buttonClassChart = ["button", "active"].join(" ");
    } else {
      buttonClassChart = ["button"].join(" ");
    }
    let buttonClassAudit: string = '';
    if (this.state.auditView) {
      buttonClassAudit = ["button", "active"].join(" ");
    } else {
      buttonClassAudit = ["button"].join(" ");
    }
    let filterClass: string = '';
    if (this.state.filters === false) {
      filterClass = ["dateFilterBlock", "hide"].join(" ");
    } else {
      filterClass = ["dateFilterBlock", "show"].join(" ");
    }

    return (
      <div className="App">
        <ButtonAppBar
          authUser={this.state.authUser}
        />
        <div className="wrapper">
          <Alert
            msg="Sync is not working properly. Data is outdated. Please contact administrator ASAP."
            open={this.state.sync_alert}
          />
          <div className={filterClass}>
            <div className="naviToCharst">
              <IconButton className={buttonClass} onClick={this.handleGridView}>
                <ViewList />
              </IconButton>
              <IconButton
                className={buttonClassChart}
                onClick={this.handleChartView}
              >
                <ShowChart />
              </IconButton>
              <IconButton
                className={buttonClassAudit}
                onClick={this.handleAuditView}
              >
                <VerifiedUser />
              </IconButton>
            </div>
            <Filters />
          </div>
          <div>
            <FiltersAlert />
          </div>
          {this.state.gridView ? <GridView  /> : null}
          {this.state.chartView ? <ChartView /> : null}
          {this.state.auditView ? <AuditView /> : null}
        </div>
        <Toasts />
      </div>
    );
  }
}

const authCondition = (authUser: any) => !!authUser;
const HomeEnhanced = withRouter(Home);
export default withAuthorization(authCondition)(HomeEnhanced);
