import React from "react";
import clsx from "clsx";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import AutoCompleteInputWrapper from "../AutocompleteInput/AutoCompleteInputWrapper";
import MoneyItems from "../../service/MoneyItems";
import Tags from "../../service/TagsService";
import MoneyAccounts from "../../service/MoneyAccountsService";
import { MoneyCategorySelect } from "../MoneyCategorySelect";
import moment from "moment";
import { selectProjects } from "../../model/selectors/projects.selector";
import { selectPayees } from "../../model/selectors/payees.selector";
import { useDispatch, useSelector } from "react-redux";
import { PayeeApiEntity } from "../../model/reducers/payees/PayeeApiEntity";
import { ProjectApiEntity } from "../../model/reducers/projects/ProjectApiEntity";
import { useState } from "react";
import { useEffect } from "react";
import { RootState } from "../../model/root.reducer";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";

import Rest from "./../../service/Rest";
import Endpoints from "../../constants/Endpoints";
import { transformToApiRequestBody } from "../../common/transformToApiRequestBody";
import PayeeService from "../../service/PayeeService";
import { payeesActions } from "../../model/actions/payees.actions";

function AddPaymentForm({
  props,
  state,
  className,
  setState,
  setValue,
  initialValues,
  moneyCategories,
  updateTags,
  tags,
  removeTagFromSelectedTags
}: any) {
  const [projectsToChooseFrom, setProjectsToChooseFrom] = useState<any>([]);

  const [amount, setAmount] = useState();

  const dispatch = useDispatch();

  const [newTagName, setNetTagname] = useState('')

  const newTagCreatig = async () => {
    updateTags(newTagName);

    try {
      const newTagCreation = await Rest({
        method: "POST",
        url: `${Endpoints.moneyItems}/tags`,
        params: {
          name: newTagName,
        },
      });
    } catch (error) {
      console.log("err", { error });
    }
  };

  const handleParent = (
    parentid = "",
    currentItem: any,
    selectId: number | string
  ) => {


    if (selectId == undefined) {
      return;
    } else if (
      !tags.includes(currentItem?.name) &&
      currentItem.type === "TAG" &&
      selectId === "tags"
    ) {
      updateTags(currentItem.name);
      return;
    } else if (currentItem && selectId !== "tags") {
      setState({
        ...state,
        values: {
          ...state.values,
          [selectId]: parentid,
        },
      });
    } else if (selectId !== "tags") {
      setValue(selectId, parentid);
    }
  };

  const handleChangeInputs = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const fieldName = target.name;

    delete state.error[fieldName];

    setValue(fieldName, value);
  };

  const payees = useSelector(selectPayees);
  const projects = useSelector(selectProjects);
  const payeesRecords = useSelector((state: RootState) => state.payees);

  useEffect(() => {
    setState({
      ...state,
      values: {
        ...state.values,
      },
    });
  }, [tags]);

  useEffect(() => {
    const payee: PayeeApiEntity | null = payeesRecords[
      state.values.payeeId as any
    ]
      ? payeesRecords[state.values.payeeId as any]
      : null;

    const projectsFiltered =
      Boolean(state.values.payeeId) && Boolean(payee)
        ? projects.filter(
            (project: ProjectApiEntity) =>
              !!payee!.projectIds.find(
                (projectId: number | string) => projectId == project.id
              )
          )
        : projects;

    setProjectsToChooseFrom(projectsFiltered);
  }, [state.values, state.values.payeeId]);

  let repeatInput = null;

  const createFormField = (
    itemClassName: string,
    label: React.ReactElement | string,
    children: React.ReactElement | string
  ) => {
    return (
      <div className={itemClassName}>
        <div className="itemLabel">{label}</div>
        <div className="itemInput">{children}</div>
      </div>
    );
  };

  const createTextField = (name: string, type = "text") => {
    return (
      <TextField
        name={name}
        type={type}
        className="textField"
        defaultValue={
          type === "date"
            ? moment(initialValues.values[name]).format("YYYY-MM-DD")
            : initialValues.values[name]
        }
        value={
          type === "date"
            ? moment(state.values[name]).format("YYYY-MM-DD")
            : state.values[name]
        }
        onBlur={handleChangeInputs}
        onChange={handleChangeInputs}
        error={!!state.error[name]}
        helperText={state.error[name]}
      />
    );
  };

  const createCurrenyField = (name: string, type = "text") => {
    // return (
    //   <MoneyInput
    //     type={type}
    //     className="textField"
    //     customInput={TextField}
    //     variant="outlined"
    //     label=""
    //     decimalSeparator="."
    //     thousandSeparator=","
    //     allowNegative={true}
    //     allowEmpty={true}
    //     currency="PLN"
    //     onBlur={createHandleMoneyChange(name)}
    //     onChange={createHandleMoneyChange(name)}
    //     error={!!state.error[name]}
    //     helperText={state.error[name]}
    //   />
    // );

    return (
      <CurrencyTextField
        variant="standard"
        currencySymbol=" "
        outputFormat="string"
        name={name}
        className="textField"
        defaultValue={initialValues.values[name]}
        value={state.values[name]}
        onBlur={handleChangeInputs}
        digitGroupSeparator=" "
        error={!!state.error[name]}
        helperText={state.error[name]}
      />
    );
  };

  if (!props.isPayed) {
    repeatInput = createFormField(
      "item",
      "Repeat by month X times",
      createTextField("payRepeat")
    );
  }

  const createNewPayee = (name: string) => {
    Rest({
      url: Endpoints.payees,
      method: "POST",
      data: JSON.stringify({
        data: {
          attributes: transformToApiRequestBody({ name }),
          type: "payees",
        },
      }),
    })
      .then((newPayee) => {
        return PayeeService.get().then((results) => {
          dispatch(payeesActions.updateResults(results));
          setValue('payeeId', newPayee.id);
        });
      })
      .catch((exception: any) => {
        alert(exception.message);
      });
  }

  return (
    <div className={clsx("wrapper", className)}>
      <form style={{ paddingBottom: 200 }}>
        <div className="error">{state.errorMSG}</div>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                name="paid"
                onChange={handleChangeInputs}
                checked={state.values.paid}
              />
            }
            label="Paid already"
          />
          <div className="addForm">
            {createFormField(
              "item name",
              "Parent (virtual)",
              <AutoCompleteInputWrapper
                value={state.values.inheritedParentId}
                fetchDataFunction={MoneyItems.getNames}
                onSelectLabel={handleParent}
                selectId="inheritedParentId"
                filter
              />
            )}

            {createFormField(
              "item name",
              "Account*",
              <AutoCompleteInputWrapper
                value={state.values.moneyAccountId}
                fetchDataFunction={MoneyAccounts.getNames}
                onSelectLabel={handleParent}
                selectId="moneyAccountId"
                error={state.error.moneyAccountId}
                state={state}
              />
            )}

            {createFormField(
              "item name",
              "Payee",
              <AutoCompleteInputWrapper
                value={state.values.payeeId}
                selectItems={payees}
                onSelectLabel={handleParent}
                selectId="payeeId"
                onCreateNewItem={createNewPayee}
              />
            )}

            {createFormField(
              "item name",
              "Project",
              <AutoCompleteInputWrapper
                value={state.values.projectId}
                selectItems={projectsToChooseFrom}
                onSelectLabel={handleParent}
                selectId="projectId"
              />
            )}

            {createFormField(
              "item",
              "Money Category",
              <MoneyCategorySelect
                value={state.values.moneyCategoryId}
                elements={moneyCategories}
                onChange={(value: any) => {
                  setValue('moneyCategoryId', value.length ? value[0].value : null);
                }}
              />
            )}

            {createFormField(
              "item",
              "Transaction on*",
              createTextField("transactionOn", "date")
            )}

            {createFormField(
              "item",
              "Taxable on",
              createTextField("taxableOn", "date")
            )}

            {createFormField("item name", "Name*", createTextField("name"))}

            {createFormField(
              "item name",
              "Amount netto*",
              createCurrenyField("amountNetto", "text")
            )}

            {createFormField(
              "item name",
              "Amount VAT*",
              createCurrenyField("amountVat", "text")
            )}

            {createFormField(
              "item name",
              "Amount brutto*",
              createCurrenyField("amountBrutto", "text")
            )}

            {createFormField(
              "item name",
              "Tags",
              <AutoCompleteInputWrapper
                value={tags}
                fetchDataFunction={MoneyItems.getTags}
                onSelectLabel={handleParent}
                selectId="tags"
                onCreateNewItem={newTagCreatig}
                tags={tags}
                multi
                removeTagFromSelectedTags={removeTagFromSelectedTags}
                onInputChange={(value:string) => setNetTagname(value)}
              />
            )}

            {!props.edit && repeatInput}
          </div>
        </FormGroup>
      </form>
    </div>
  );
}

export default AddPaymentForm;
