import { combineReducers } from "redux";
import {
  filtersReducer,
  FiltersState,
} from "./reducers/filters/filters.reducer";
import {
  moneyCategoriesReducer,
  MoneyCategoriesState,
} from "./reducers/moneyCategories/moneyCategories.reducer";
import {
  moneyItemsReducer,
  MoneyItemsState,
} from "./reducers/moneyItems/moneyItems.reducer";
import { payeesReducer, PayeesState } from "./reducers/payees/payees.reducer";
import {
  projectsReducer,
  ProjectsState,
} from "./reducers/projects/projects.reducer";
import {
  balanceReducer,
  BalanceState,
} from "./reducers/balance/balance.reducer";
import { peopleReducer, PeopleState } from "./reducers/people/people.reducer";
import { toastsReducer, ToastsState } from "../toasts/toasts.reducer";
import { dealsReducer, DealsState } from "./reducers/deals/deals.reducer";
import {
  projectGroupsReducer,
  ProjectGroupState,
} from "./reducers/project-groups/project-groups.reducer";
import { appReducer, AppState } from "./reducers/app/app.reducer";
import { tagsReducer, TagsState } from "./reducers/tags/tags.reducers";

export const rootReducer = combineReducers({
  tags: tagsReducer,
  moneyItems: moneyItemsReducer,
  filters: filtersReducer,
  projects: projectsReducer,
  payees: payeesReducer,
  moneyCategories: moneyCategoriesReducer,
  balance: balanceReducer,
  toasts: toastsReducer,
  people: peopleReducer,
  deals: dealsReducer,
  projectGroups: projectGroupsReducer,
  app: appReducer,
});

export type RootState = {
  tags: TagsState;
  moneyItems: MoneyItemsState;
  filters: FiltersState;
  projects: ProjectsState;
  payees: PayeesState;
  moneyCategories: MoneyCategoriesState;
  balance: BalanceState;
  toasts: ToastsState;
  people: PeopleState;
  deals: DealsState;
  projectGroups: ProjectGroupState;
  app: AppState;
};
