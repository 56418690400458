import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
let openAcceptboxFn;
class Acceptbox extends React.Component {
  state = {
    open: false,
    acceptDelete: false,
  };
  componentDidMount() {
    openAcceptboxFn = this.openDialog;
  }
  openDialog = (message, callback) => {
    this.callback = callback;
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
      acceptDelete: false,
    });
  };
  acceptDelete = () => {
    this.callback();
    this.setState({
      acceptDelete: true,
      open: false,
    });
  };
  render() {
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.acceptDelete} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export function openDialog(message, callback) {
  openAcceptboxFn(null, callback);
}
export default Acceptbox;
